.certificate {
  &__title {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.2;
    text-transform: uppercase;
    color: #353635;
  }

  &__image-wrapper {
    margin-bottom: 20px;
    width: 250px;
    height: 350px;
    padding: 20px;
    background: #FFFFFF;
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__download {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 45px;
    padding: 0 10px;
    font-size: 14px;
    font-weight: 700;
    line-height: 43px;
    text-transform: uppercase;
    background-color: transparent;
  }

  &__icon {
    width: 20px;
    height: 20px;
    margin-right: 12px;
  }

  @media only screen and (min-width: 1200px) {
    &__title {
      text-align: center;
    }

    &__image-wrapper {
      width: 270px;
      height: 380px;
      padding: 28px;
      box-shadow: 0 5px 20px rgba(0,0,0,.1);
    }

    &__download {
      margin-right: auto;
      margin-left: auto;
    }
  }
}