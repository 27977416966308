@import "common/variables.scss";
@import "common/animation.scss";
@import "common/main.scss";
@import "common/header.scss";
@import "common/main-page.scss";
@import "common/footer.scss";
@import "common/category-page.scss";
@import "common/shop.scss";
@import "common/products.scss";
@import "common/swiper.scss";
@import "common/single.scss";
@import "common/basket.scss";
@import "common/contact.scss";
@import "common/blog.scss";
@import "common/article.scss";
@import "common/about.scss";
@import "common/404.scss";
@import "common/popup.scss";
@import "common/slick-img.scss";
@import "common/input.scss";
@import "common/slick.scss";
@import "common/media-queries.scss";
