.main {
  position: relative;
  overflow: hidden;
  padding-bottom: 50px;
  &__wrapper {
    position: absolute;
    top: -11px;
    right: 0;
  }
  &__image {
    padding-top: 30px;
  }
  &__block {
    margin-top: 37px;
    &--title {
      font-size: 25px;
      line-height: 33px;
      font-weight: bold;
      letter-spacing: 0.05em;
      color: #333333;
    }
    &--descr {
      margin-top: 4px;
    }
    &--btns {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 25px;
      & .bttn {
        width: 49%;
      }
    }
  }
}
.we-bee {
  padding-top: 11px;
  padding-bottom: 50px;
  position: relative;
  &__block {
    &--descr {
      margin-top: 9px;
      padding-right: 5px;
    }
  }
  &__do {
    &.bee__do {
      padding-right: 16px;
      & .do-block::after {
        right: 8px;
      }
    }
    &.we__do {
      padding-left: 16px;
      & .do-block::after {
        left: 8px;
      }
    }
    &-title {
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      color: #888888;
      margin-top: 32px;
      margin-bottom: 3px;
      padding-bottom: 10px;
      position: relative;
      text-align: center;
      &::before {
        content: '';
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        width: 50px;
        height: 3px;
        background: #D5A53C;
      }
    }
    &-block {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin-top: 30px;
      position: relative;
      &-txt {
        margin: 34px auto 0;
        padding: 0px;
        max-width: 149px;
        min-height: 34px;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          top: -18px;
          left: 50%;
          transform: translateX(-50%);
          width: 8px;
          height: 8px;
          background: #D5A53C;
          border-radius: 50%;
          z-index: 2;
        }
        &::after {
          content: '';
          position: absolute;
          top: -30px;
          left: 50%;
          transform: translateX(-50%);
          width: 1px;
          height: 15px;
          background: #E9E2D2;
        }
      }
    }
  }
  &__number {
    display: block;
    font-weight: bold;
    font-size: 30px;
    line-height: 37px;
    margin-top: 55px;
    text-align: center;
    color: #EED8AA;
    margin-top: 55px;
    position: relative;
    &::before, &::after {
      content: '';
      position: absolute;
      top: 19px;
      width: 10px;
      height: 1px;
      background: #E9E2D2;
    }
    &::before {
      left: -6px;
    }
    &::after {
      right: -6px;
    }
  }
}
// PRODUCTS IN PRODUCTS.SCSS
.instagram {
  padding-top: 48px;
  padding-bottom: 50px;
  &__block {
    position: relative;
    padding: 32px 0;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 160px;
      height: 2px;
      background: #D5A53C;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 160px;
      height: 2px;
      background: #D5A53C;
    }
    &--descr {
      margin-top: 26px;
      padding: 0 10px;
    }
    &--images {
      margin-top: 27px;
    }
    &--icons {
      text-align: center;
      margin-top: 35px;
      & a:first-child {
        margin-right: 25px;
      }
    }
  }
  &__mob {
    display: block;
  }
  &__web {
    display: none;
  }
  &_post-item {
    max-height: 181px;
  }
}
.history {
  padding-top: 20px;
  padding-bottom: 35px;
  &__block {
    &--subtitle {
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      margin-top: 18px;
    }
    &--descr {
      margin-top: 13px;
      padding-right: 25px;
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-thumb {
        background: rgba(143, 109, 32, 0.2);
        border-radius: 10px;
      }
    }
  }
}
.proposal {
  background: #FFFBF0;
  padding-top: 60px;
  padding-bottom: 40px;
  position: relative;
  overflow: hidden;
  &__wrapper {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
  &__title {
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.05em;
  }
  &__descr {
    margin-bottom: 16px;
  }
  
  .form-wrapp {
    max-width: 310px;
    margin: auto;
    background: #fff;
    padding-top: 38px;
    padding-right: 12px;
    padding-bottom: 17px;
    padding-left: 18px;
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    z-index: 2;
    form {
      padding: 0 5px;
      label {
        width: 100%;
        background-color: #fff;
        font-size: 12px;
        text-indent: 20px;
        line-height: 15px;
        color: #C4C4C4;
        margin-bottom: 8px;
        textarea {
          height: 100px;
          border-radius: 20px !important;
        }
      }
      .form__input {
        padding: 9px 20px;
        margin: 0;
        margin-top: 2px;
        width: 100% !important;
        font-size: 14px;
        line-height: 1.2;
        color: #000000;
        background: rgba(255, 255, 255, 0.1);
        border: 1px solid rgba(143, 109, 32, 0.2);
        box-sizing: border-box;
        border-radius: 100px;
      }

      .btn__submit {
        margin-top: 5px;
      }
    }
  }
}
@media (min-width:768px) {
  .main {
    &__block {
      margin-top: 77px;
    }
    &__image {
      order: 2;
    }
  }
}
@media (min-width:1200px) {
  .main {
    margin-top: 0;
    padding-top: 30px;
    &__wrapper {
      top: 0px;
    }
    &__block {
      margin-top: 175px;
      &--title {
        font-size: 55px;
        line-height: 67px;
      }
      &--btns {
        margin-top: 35px;
        padding-right: 20px;
      }
    }
    &__image {
      margin-top: 70px;
      position: relative;
      right: -30px;
    }
  }
  .we-bee {
    padding-top: 55px;
    padding-bottom: 90px;
    &__block {
      flex-direction: row;
      align-items: center;
      padding-right: 200px !important;
      &--descr {
        margin-top: 3px;
      }
    }
    &__do {
      margin-top: 45px;
      &-title {
        font-size: 16px;
        margin-top: 46px;
        margin-bottom: 10px;
        line-height: 19px;
        text-align: left;
        &::before {
          bottom: -4px;
          left: 80px;
        }
      }
      &-block {
        display: flex;
        flex-direction: row;
        margin-top: 20px;
        &-txt {
          margin: 0 -24px 0 50px;
          padding: 12px 24px;
          min-width: 170px;
          max-width: 230px;
          font-size: 16px;
          line-height: 19px;
          border: 1px solid #E9E2D2;
          box-sizing: border-box;
          border-radius: 100px;
          &::before {
            top: 50%;
            left: 0;
            transform: translate(-50%,-50%);
          }
          &::after {
            top: 50%;
            left: -20px;
            transform: translate(-50%,-50%);
            width: 30px;
            height: 1px;
          }
        }
      }
      &.we__do {
        padding-left: 40px;
      }
    }
    &__number {
      position: inherit;
      margin-top: 46px;
      padding-left: 55px !important;
      transform: none;
      text-align-last: left;
      &::before {
        left: -120px;
        width: 150px;
      } 
      &::after {
        right: -81px;
        width: 150px;
      }
    }
    .do-block {
      max-width: 1050px;
      &:nth-child(2n){
        margin-left: 100px;
      }
      .col-5:first-child p {
        max-width: 170px;
      }
    }
  }
// PRODUCTS IN PRODUCTS.SCSS
  .instagram {
    padding-top: 80px;
    &__block {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 97px;
      margin-bottom: 119px;
      &--descr {
        margin-top: 33px;
        padding: 0 40px;
      }
    }
    &__mob {
      display: none;
    }
    &__web {
      display: block;
    }
  }
  .history {
    padding-bottom: 21px;
    &__block {
      &--title {
        padding-right: 100px;
      }
      &--subtitle {
        margin-top: 20px;
      }
      &--descr {
        max-height: 130px;
        overflow-y: scroll;
      }
    }
  }
  .proposal {
    padding-bottom: 107px;
    &__wrapper {
      display: block;
      top: 0;
      left: 50%;
      z-index: 0;
      transform: translateX(-50%);
    }
    .form-wrapp {
      max-width: 410px;
      margin: auto;
      padding: 27px 30px 36px;
    }
    &__title {
      font-size: 18px;
    }
    &__descr {
      margin-top: 9px;
      padding: 0 30px;
    }
  }
}

@media (min-width: 1200px) {
  .we-bee {
    &__do {
      &-title {
        &::before {
          left: 25px;
        }
      }
    }
  }
}
.main-first-screen__bg{
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #FFFCEF;
  width: 80%;
  z-index: -1;
}
.main-first-screen__img{
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  overflow: hidden;
  z-index: 3;
}
.main-first-screen__img img {
  height: 100vh;
  width: 100%;
  object-fit: cover;
}
.main-img-decore{
  position: absolute;
  right: 34%;
  bottom: 100px;
  z-index: 2;
}
.main-img-decore-two{
  position: absolute;
  right: 0;
  bottom: -100px;
  z-index: 6;
}
.main-first-screen_padd{
  padding-top: 191px;
  padding-bottom: 140px;
}
.first-screen-wrp{
  position: relative;
  z-index: 9;
}
.first-screen-wrp_padd{
  padding-left: 40px;
}
.first-screen__eko-text{
  line-height: normal;
  font-size: 11px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #444444;
}
.first-screen__eko-text_padd{
  padding-bottom: 10px;
}
.first-screen__title_padd{
  padding-bottom: 25px;
}
.first-screen__btn{
  width: 247px;
}
.first-screen__btn_padd{
  margin-left: 55px;
}
.images-item{
  position: absolute;
  z-index: 1;
}
.images-item_bg{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 25px 25px 90px rgba(0, 0, 0, 0.15);
  background: rgba(0, 0, 0, 0.6);
}

.images-item_bg-2{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
}
.images-item_active{
  z-index: 2;
}

.images-item_one{
  top: 0;
  right: 0;
}
.images-item_two{
  top: 30%;
  left: 30%;
}
.images-item_three{
  left: -70px;
  bottom: 45px;
}
.images-item_four{
  right: -120px;
  bottom: 0;
}
.images-item__title{
  position: absolute;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #FFFFFF;
  z-index: 9;
}
.images-item_one .images-item__title{
  top: 20px;
  left: 20px;
}
.images-item_two .images-item__title{
  bottom: 20px;
  left: 20px;
}
.images-item_three .images-item__title{
  bottom: 120px;
  left: -70px;
  transform: rotate(-90deg);
}
.images-item_four .images-item__title{
  bottom: 120px;
  right: -80px;
  transform: rotate(-90deg);
}

// .main-slider-images__bg{
//   position: absolute;
//   width: 385px;
//   height: 430px;
//   top: 31%;
//   right: -4%;
//   background: rgba(154, 166, 92, 0.5);
// }
// .main-slider-images__decore-one{
//   position: absolute;
//   right: -65px;
//   top: 18%;
// }
// .main-slider-images__decore-two{
//   position: absolute;
//   left: 52%;
//   bottom: 11%;
// }
.slick-arrow.slick-prev img {
  margin-left: -15px;
}
.slick-arrow.slick-next img {
  margin-right: -15px;
  margin-top: -2px;
}

.product{
  position: relative;
}
.product_padd{
  padding: 150px 0 90px;
}
.product-item{
  z-index: 9;
  position: relative;
  display: inline-block;
}
.product-item_padd{
  margin-top: 200px;
}
.product-bg_green{
  background: rgba(154, 166, 92, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}
.product-bg_yellow{
  background-image: url('../images/main-page/honey_bg.jpg');
  position: absolute;
  top: 80px;
  bottom: 50px;
  right: 80px;
  z-index: 4;
  width: 40%;
}
.product-bg_white{
  position: absolute;
  background-color: #FCFCFC;
  left: 0;
  bottom: 0;
  right: 0;
  height: 87%;
  z-index: 2;
}
.product-bg_green-small{
  background: rgba(154,166,92,.5);
  position: absolute;
  top: 118px;
  bottom: 50px;
  right: 0;
  z-index: 5;
  width: 80px;
}
.product-item__title{
  position: absolute;
  left: -59%;
  bottom: 47%;
  transform: rotate(-90deg);
  transition: 500ms;
  -webkit-transition: 500ms;
}
.product-item__title div{
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #4F4F4F;
}
.product-item__title_small {
  left: -33%;
  bottom: 47%;
  transition: 500ms;
  -webkit-transition: 500ms;
}
.product-item__choise-wrp{
  position: absolute;
  text-align: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  transition: 500ms;
  -webkit-transition: 500ms;
  box-shadow: 25px 25px 90px rgba(0, 0, 0, 0.15);
}
.product-item__choise{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  transition: 500ms;
  -webkit-transition: 500ms;
}

.product-item__choise-two{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 50%;
  transition: 500ms;
  -webkit-transition: 500ms;
}
.product-item__choise:hover .product-item__choise-bg ,
.product-item__choise-two:hover .product-item__choise-bg{
  opacity: 1;
}
.product-item__choise_bg{
  background-color: #fff;
}
.product-item__choise_padd{
  padding: 5px 25px;
}
.product-item__choise-title-wrp {
  display: inline-block;
  background-color: #fff;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 2;
  padding: 5px 10px;
  opacity: 1;
  transition: 500ms;
  -webkit-transition: 500ms;
}
.product-item__choise_line{
  position: absolute;
  width: 1px;
  height: 75px;
  background-color: #b4b4b4;
  left: 50%;
  z-index: 4;
  bottom: 5px;
}
.product-item__choise-title{
  font-size: 16px;
  text-align: center;
  color: #333333;
  display: inline-block;
  letter-spacing: 0.1em;
}
.product-item__choise-title span{
  font-size: 20px;
  text-align: center;
  color: #333333;
  display: inline-block;
  letter-spacing: 0.1em;
  border-bottom: 1px solid transparent;
  transition: 500ms;
  -webkit-transition: 500ms;
}
.product-item__choise-title:hover span{
  border-bottom: 1px solid #FEB101;
}
.product-item__choise-title_padd{
  padding: 15px 0;
}
.product-item__choise-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 1;
  background: rgba(0, 0, 0, 0.4);
  opacity: 0;
  transition: 500ms;
  -webkit-transition: 500ms;
}
.product-item__choise-wrp:hover {
  opacity: 1;
}
.product-item__choise-wrp .product-item__choise:hover  .product-item__choise-title span{
  border-bottom: 1px solid #FEB101;
  color: #feb101;
}
.product-item__choise-wrp .product-item__choise-two:hover  .product-item__choise-title span{
  border-bottom: 1px solid #FEB101;
  color: #feb101;
}


.product-bg_decore{
  position: absolute;
  z-index: -1;
}
.product-bg_decore-1{
  top: -23%;
  left: 12%;
}
.product-item:hover .product-item__title_small {
  bottom: 48%;
}
.product-item:hover .product-item__title {
  bottom: 50%;
}


.product-bg_decore-2 {
  right: -16%;
  bottom: 30px;
}
.product-bg_decore-3 {
  top: -19%;
  left: 4%;
}
.product-bg_decore-4 {
  right: -12%;
  top: 40px;
}

.logo-slider{
  background-color: #fff;
  text-align: center;
}
.logo-slider_padd{
  padding: 60px 0;
}
.logo-slider img{
  height: 90px;
  max-width: 100%;
  width: auto !important;
}


.instagramm{
  background-color: #fff;
  text-align: center;
}
.instagramm_padd{
  padding: 60px 0 125px;
}

.instagramm-text{
  border-top: 3px solid #9AA65C;
  border-bottom: 3px solid #9AA65C;
}
.instagramm-text_padd{
  margin: 44px 29px;
  padding: 107px 0;
}
.instagramm-text__title{
  line-height: normal;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #4F4F4F;
}
.instagramm-text__title_padd{
  padding-bottom: 40px;
}
.instagramm-text__descr{
  line-height: 24px;
  font-size: 16px;
  text-align: center;
  color: #4F4F4F;
}
.instagramm-text__descr span{
  line-height: 24px;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #4F4F4F;
}
.instagramm-text__descr span a{
  color: #4F4F4F;
  transition: 500ms;
  -webkit-transition: 500ms;
  &:hover{
    color: #feb101;
  }
}
.instagramm_bg {
  background: #FFFDEE;
}
.instagramm_bg-padd{
  padding: 100px 0;
  margin-bottom: 100px;
}

.instagram-pics{
  list-style: none;
}
.instagram-pics li{
  display: inline-block;
  width: 49%;
  padding-left: 15px;
  padding-bottom: 15px;
}
.instagram-pics  img{
  display: inline-block;
  max-width: 100%;
  height: auto;
  transition: 500ms;
  -webkit-transition: 500ms;
}

.instagram2 .instagram-pics li:nth-child(1), 
.instagram2 .instagram-pics li:nth-child(2), 
.instagram2 .instagram-pics li:nth-child(3), 
.instagram2 .instagram-pics li:nth-child(4) {
    display: inline-block;
}

.instagram2 .instagram-pics li:nth-child(5), 
.instagram2 .instagram-pics li:nth-child(6),
.instagram2 .instagram-pics li:nth-child(7), 
.instagram2 .instagram-pics li:nth-child(8), 
.instagram2 .instagram-pics li:nth-child(9), 
.instagram2 .instagram-pics li:nth-child(10), 
.instagram2 .instagram-pics li:nth-child(11), 
.instagram2 .instagram-pics li:nth-child(12) {
    display: none;
}

@media (min-width:992px) {
  .instagram2 .instagram-pics li:nth-child(1),
  .instagram2 .instagram-pics li:nth-child(2),
  .instagram2 .instagram-pics li:nth-child(3),
  .instagram2 .instagram-pics li:nth-child(4),
  .instagram2 .instagram-pics li:nth-child(5),
  .instagram2 .instagram-pics li:nth-child(6){
    display: none;
  }
  .instagram2 .instagram-pics li:nth-child(7), 
  .instagram2 .instagram-pics li:nth-child(8), 
  .instagram2 .instagram-pics li:nth-child(9), 
  .instagram2 .instagram-pics li:nth-child(10), 
  .instagram2 .instagram-pics li:nth-child(11), 
  .instagram2 .instagram-pics li:nth-child(12) {
      display: inline-block;
  }
}
.instagram-pics li a {
  display: block;
  overflow: hidden;
}
.instagram-pics li a:hover img {
  transform: scale(1.1);
}
.mob-bg-img{
  display: none;
}

// .main-form{
//   position: relative;
//   background: #F9FEF8;
// }
// .main-form_padd{
//   padding: 25px 0 90px;
// }
// .form-wrp{
//   position: relative;
//   background: #FFFFFF;
//   box-shadow: 25px 25px 80px rgba(0, 0, 0, 0.15);
//   border-radius: 10px;
//   width: 510px;
//   margin: 0 auto 77px;
//   z-index: 5;
// }
// .form-wrp_padd {
//   padding: 50px 55px 70px;
// }
// .form__title{
//   line-height: normal;
//   font-size: 32px;
    // font-weight: bold;
//   text-align: center;
//   letter-spacing: 0.05em;
//   color: #1A1A1A;
// }
// .form__title_padd{
//   padding-bottom: 8px;
// }
// .form__text{
//   line-height: 24px;
//   font-size: 16px;
//   text-align: center;
//   letter-spacing: 0.05em;
//   color: #1A1A1A;
// }
// .form__text_padd{
//   padding-bottom: 50px;
// }
// .form__input{
//   background: #EAEAEA;
//   border-radius: 3px;
//   line-height: 15px;
//   font-size: 14px;
//   letter-spacing: 0.05em;
//   color: #777777;
//   padding: 15px 30px;
//   display: table;
//   width: 95%;
//   margin: 0 auto;
// }
// .form__submit{
//   margin: 15px auto 0;
//   display: table;
//   width: 95%;
// }
// .form__decore-bg{
//   position: absolute;
//   top: 70px;
//   bottom: 125px;
//   right: 16%;
//   width: 30%;
//   background-image: url('../images/main-page/honey_bg.jpg');
//   z-index: 4;
// }
// .form__decore-bg-two{
//   background: #FCFCFC;
//   position: absolute;
//   top: 133px;
//   bottom: 180px;
//   left: 0;right: 0;
// }
// .form__decore-bg-honey{
//   position: absolute;
//   z-index: 4;
//   left: 0;
//   top: 30%;
// }

// .form-wrp-bee{
//   position: absolute;
//   z-index: 6;
//   right: -16%;
//   bottom: 12%;
// }
// .form-wrp-decore{
//   position: absolute;
//   z-index: 4;
//   top: -7%;
//   right: 27%;
// }
// .form__decore-two{
//   position: absolute;
//   z-index: 3;
//   bottom: 31%;
//   right: 12.5%;
// }
// .form__decore-three{
//   position: absolute;
//   z-index: 3;
//   top: 16%;
//   right: 0;
// }

// div.wpcf7-mail-sent-ok {
//   display: none !important;
// }

// .main-cont , .sub-cont{
//   display: block;
// }
// .main-cont.hidden {
//   display: none;
// }
// .sub-cont.hidden {
//   display: none;
// }