.blog{
    position: relative;
}
.blog_bg{
    background: #FFFDEE;
    position: absolute;
    right: 0;
    top: 0;
    width: 80%;
    height: 935px;
    z-index: -1;
}
.blog_padd{
    padding-top: 63px;
    padding-bottom: 263px;
}
.blog-item_padd{
    padding-bottom: 50px;
}
.blog .blog-item__img-wrp{
    border-radius: 20px;
}
.blog-item__title_big{
    display: block;
    line-height: normal;
    font-size: 45px;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #333333;
}
.blog-item__title_big_padd{
    padding-bottom: 45px;
}
.blog-item__text_big{
    line-height: 24px;
    font-size: 16px;
    text-align: center;
    color: #4a4a4a;
    padding: 0 48px 37px;
}
.blog-item__text_big-padd{
    padding-bottom: 48px;
}
.blog-item_padd_big{
    margin-bottom: 55px;
}
.blog .blog-item__text_padd {
    padding-bottom: 10px;
}
.pagination{
    position: relative;
    padding-top: 116px;
    display: flex;
    justify-content: center;
    align-items: center; 
}
.page-numbers{
    line-height: 33px;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.04em;
    color: #B6B6B6;
    padding: 0 15px;
    transition: 500ms;
    -webkit-transition: 500ms;
    cursor: pointer;
}
.page-numbers.current{
    line-height: 33px;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.04em;
    color: #F7AF44;
}
.page-numbers:hover{
    color: #B6B6B6;
}
.page-numbers.current{
    color: #F7AF44;
}
.page-numbers.next , .page-numbers.prev{
    position: absolute;
    font-size: 10px;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    color: #333333;
    display: inline-block;
    vertical-align: middle;
    padding: 0;
} 
.page-numbers.next span, 
.page-numbers.prev span{
    padding-top: 4px;
    display: inline-block;
    vertical-align: middle;
}
.page-numbers.prev{
    left: 0;
}
.page-numbers.next{
    right: 0;
}
.page-numbers.prev img{
    padding-right: 12px;
}
.page-numbers.next img{
    padding-left: 12px;
}
.blog-item__img_big{
    object-fit: cover;
    height: 712px;    
}

.blog-items-wrp_padd{
    padding: 55px 0 110px;
    margin-bottom: 100px;
}
.blog-title{
    display: block;
    line-height: normal;
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #333333;
    text-align: center;
}
.blog-title_padd{
    padding-bottom: 55px;
}
.blog-items-wrp{
    background: #F9FEF8;
}
.blog-item{
    display: inline-block;
}
.blog-item__img-wrp{
    position: relative;
    border-radius: 3px;
    overflow: hidden;
}
.blog-item__img-bg{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    opacity: 0;
    transition: 500ms;
    -webkit-transition: 500ms;
}
.blog-item__img{
    width: 100%;
}
.blog-item__title{
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #444444;
}
.blog-item__title_padd{
    padding: 25px 0 15px;
}
.blog-item__text{
    line-height: 16px;
    font-size: 13px;
    color: #4a4a4a;
}
.blog-item__text_padd{
    padding-bottom: 20px;
}
.blog-item__link{
    display: inline-block;
    line-height: 23px;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #444444;
    border-bottom: 2px solid #444444;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
}
.blog-item__link{
    padding: 7px 10px;
}
.blog-item__link:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #444444;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}
.blog-item:hover{
    .blog-item__link{
        color: #fff;
    }
    .blog-item__link:before {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
    }
    .blog-item__img-bg{
        opacity: 1;
    }
    .blog-item__link{
        border-bottom: 2px solid #444444;
    }
}
