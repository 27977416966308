.modal {
    .modal-content {
        border-radius: 20px;
        max-width: 410px;
    }
    .form-pop-dialog {
        width: auto;
    }
    .form-wrapp {
        max-width: 330px;
        margin: auto;
        background: #fff;
        padding-top: 38px;
        padding-right: 12px;
        padding-bottom: 17px;
        padding-left: 18px;
        box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
        z-index: 2;
        form {
          padding: 0 5px;
          label {
            width: 100%;
            background-color: #fff;
            font-size: 12px;
            text-indent: 20px;
            line-height: 15px;
            color: #C4C4C4;
            margin-bottom: 8px;
            textarea {
              height: 100px;
              border-radius: 20px !important;
            }
          }
          .form__input {
            padding: 9px 20px;
            margin: 0;
            margin-top: 2px;
            width: 100% !important;
            font-size: 14px;
            line-height: 1.2;
            color: #000000;
            background: rgba(255, 255, 255, 0.1);
            border: 1px solid rgba(143, 109, 32, 0.2);
            box-sizing: border-box;
            border-radius: 100px;
          }
    
          .btn__submit {
            margin-top: 5px;
          }
        }
    }
    @media only screen and (min-width: 1200px) {
        .form-wrapp {
            max-width: 410px;
            margin: auto;
            padding: 27px 30px 36px;
        }
    }
}