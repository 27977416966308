.faq {
  &__title  {
    margin-bottom: 20px;
  }

  @media only screen and (min-width: 768px) {
    &__collapses {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      align-content: flex-start;
      margin-right: -15px;
      margin-left: -15px;
    }

    &__item {
      flex: 0 0 50%;
      max-width: 50%;
      padding-right: 15px;
      padding-left: 15px;
    }
  }

  @media only screen and (min-width: 1200px) {
    &__title {
      margin-bottom: 40px;
    }
  }
}