.socials {
  display: flex;
  
  &__link {
    width: 35px;
    height: 35px;
    color: #D5A53C !important;

    &:hover,
    &:focus,
    &:active {
      color: #D5A53C !important;
    }

    &:not(:last-of-type) {
      margin-right: 10px;
    }
  }

  &__icon {
    width: 100%;
    height: 100%;
  }
}