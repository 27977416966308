.team-block {
  &__container {
    position: relative;
  }

  &__title {
    margin-bottom: 20px;
  }

  &__slider {
    padding-right: 150px;
    padding-bottom: 65px;
    padding-left: 150px;
    margin-right: -150px;
    margin-left:  -150px;
  }

  &__slide {
    flex-basis: 165px;
    max-width:  165px;
    margin-right: 70px;
  }

  &__thumb-slider {
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }


  @media only screen and (min-width: 768px) {
    &__slider {
      position: relative;
      padding-right: 15px;
      padding-left: 15px;
      margin-right: -15px;
      margin-left:  -15px;

      &::after,
      &::before {
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 2;
        width: 15px;
        content: "";
      }

      &::before {
        left: 0;
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 1) 33%,
          rgba(255, 255, 255, 0.7) 66%,
          rgba(255, 255, 255, 0) 100%
		);
      }

      &::after {
        right: 0;
        background: linear-gradient(
          to left,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 1) 33%,
          rgba(255, 255, 255, 0.7) 66%,
          rgba(255, 255, 255, 0) 100%
        );
      }
    }

    &__slide {
      flex-basis: 337.5px;
      max-width: 337.5px;
      margin-right: 15px;
    }
  }

  @media only screen and (min-width: 992px) {
    &__slide {
      flex-basis: 457.5px;
      max-width: 457.5px;
    }
  }

  @media only screen and (min-width: 1200px) {
    &__title {
      margin-bottom: 45px;
    }

    &__slider {
      margin-right: 170px;
    }

    &__slide {
      flex-basis: 485px;
      max-width: 485px;
    }
  }
}