.footer{
  z-index: 999;
  position: relative;
  border-top: 2px solid #D5A53C;

  & .brd-top {
    padding-top: 33px;
  }
}
#foot-menu {
  list-style-type: none;
}
.policy {
  &__block {
    text-align: center;
    & a {
      font-weight: bold;
      display: block;
      margin-top: 25px;
      font-size: 16px;
    }
  }
}
.contact {
  &__block {
    text-align: center;
    &--phone {
    color: #607025;
    display: block;
    font-size: 16px;
    line-height: 19px;
    margin-top: 18px;
    }
    &--mail {
      display: block;
      font-size: 16px;
      line-height: 19px;
      margin-top: 15px;
    }
    &--phone:hover {
      color: #607025;
    }
  }
}
.address {
  &__block {
    text-align: center;
    &--text {
      font-size: 16px;
      margin-top: 16px;
      line-height: 22px;
      padding: 0 10px;  
    }
  }
}
.social {
  &__block {
    order: -1;
    text-align: center;
    &-icon {
      position: relative;
      .normal {
        transition: opacity 0.5s;
      }
      .icon-hide {
        position: absolute;
        left: 0;
        z-index: -1;
      }
      &:hover {
        .normal {
          opacity: 0;
        }
      }
    }
    &--facebook {
      margin-right: 25px;
    }
  }
}
.developed {
  margin-top: 50px;
}
.development-bonum {
  opacity: 0.8;
  transition: opacity 0.5s;
}
.development-bonum:hover {
  opacity: 1;
}

.copy-right{
  line-height: 15px;
  font-size: 12px;
  color: #888888;
}
.idea, .idea:hover{
  font-family: 'Cero Pro', sans-serif;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  color: #888888;
  display: inline-block;
}

@media (min-width:1200px) {
  .footer {
    margin-top: 7px;
    border: none;

    .container {
      padding-right: 30px;
      padding-left: 30px;
    }
    
    & .brd-top {
      padding-top: 39px;
      border-top: 2px solid #D5A53C;
    }
    & .no-gutters {
      margin-right: -15px;
    }
  }
  .contact {
    &__block {
      text-align: left;
      &--phone {
        margin-top: 0;
        font-size: 14px;
      }
      &--mail {
        font-size: 14px;
        margin-top: 7px;
      }
    }
  }
  .address__block {
    text-align: left;
    &--text {
      margin-top: 0;
      padding: 0 5px;
      font-size: 14px;
    }
  }
  .policy__block a {
    text-align: left;
    font-size: 14px;
    margin-top: 0;
    &:nth-child(2) {
      margin-top: 10px;
    }
  }
  .social__block {
    order: 2;
    text-align: right;
  }
  .developed {
    margin-top: 36px;
  }
}