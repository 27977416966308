.about-page {
  padding-top: 60px;

  .application-block {
    margin-bottom: 35px;
  }

  .posts-block {
    margin-bottom: 35px;
  }

  .certificates-block {
    margin-bottom: 50px;
  }

  .salespeople-block {
    margin-bottom: 38px;
  }

  .why-we {
    margin-bottom: 50px;
  }

  .about-hero {
    margin-bottom: 50px;
  }

  .reviews-block  {
    margin-bottom: 35px;
  }

  @media only screen and (min-width: 768px) {
    .posts-block {
      margin-bottom: 5px;
    }
  }

  @media only screen and (min-width: 1200px) {
    .certificates-block {
      margin-bottom: 67px;
    }

    .why-we {
      margin-bottom: 80px;
    }

    .reviews-block {
      margin-bottom: 40px;
    }

    .proposal {
      background-color: transparent;
      padding-bottom: 135px;
    }
  }
}