.blog_bg-full{
    background: #FFFDEE;
    position: absolute;
    right: 0;
    top: 0;
    width: 80%;
    height: 100%;
    z-index: -1;
}

.artic__next-prev span{
    padding: 0 10px;
    text-transform: uppercase;
}
.artic__title{
    font-size: 45px;
    font-weight: 700;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #333333;
}
.artic__title_pad{
    padding-bottom: 50px;
}
.artic__thumbtail_padd{
    padding-bottom: 45px;
}
.artic__thumbtail img{
    width: 100%;
}

.artic__text{
    
    p{
        font-weight: normal;
        line-height: 24px;
        font-size: 16px;
        color: #4a4a4a;
        padding-bottom: 28px;
        text-align: justify;
    }
    
    .artic__half{
        width: 49% !important;
        display: inline-block;
        padding-bottom: 40px;
        padding-right: 20px;
    }
    blockquote{
        margin: 0px 0px 20px 38px;
        padding: 12px 0 12px 25px;
        border-left: 1px solid #000;
        line-height: 24px;
        font-size: 16px;
        color: #8D8D8D;
    }
    blockquote p {
        padding-bottom: 0;
    }
    img {
        width: 49%;
        height: auto;
        display: inline-block;
    }
    .alignright{
        float: right;
    }
    .alignleft {
        float: left;
    }
    .aligncenter{
        width: 100%  !important;
        display: block;
        height: auto;
        float: none;
    }
}
.artic__next-prev{
    display: inline-block;
    display: flex;
    justify-content: space-between;
    padding-top: 65px;
}
// .artic__next-prev_padd{
//     padding-top: 65px;
//     padding-bottom: 55px;
// }

.artic__next-prev-wrp{
    position: relative;
    
}
.artic__next-prev-wrp a{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.artic__next-prev-wrp img {
    height: 14px;
}
.artic__next-prev-wrp span {
    font-size: 10px;
    letter-spacing: 0.25em;
}
.artic_padd{
    padding-bottom: 160px;
}

.share-fb{
    position: fixed;
    right: 10%;
    top: 30%;
    box-shadow: 25px 25px 90px rgba(0, 0, 0, 0.15);
    border-radius: 50%; 
}