.map-block {
  &__title  {
    margin-bottom: 20px;
  }

  &__container {
    height: 500px;
    background-color: #f8f8f8;
  }

  @media only screen and (min-width: 1200px) {
    &__title {
      margin-bottom: 35px;
    }
  }
}