.header{
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
  background-color: #fff;
  box-shadow: 7px 7px 15px rgba(0, 0, 0, 0.07);
}
.home .header {
  background-color: transparent;
  box-shadow: none;
}

.header_padd{
  padding: 10px 0;
}

.number:hover p {
  color: #607025;
}

.header .row {
  display: flex;
  align-items: center;
}

.header-logo {
  margin-left: -7px;
  width: 209px;
}

.swicher__lang {display: none;}
.navbar-toggler .icon-bar { display: block; width: 32px; height: 3px; border-radius: 1px; background: #607025; }
.navbar-toggler .icon-bar+.icon-bar { margin-top: 4px; }
.number p{
  font-size: 12px;
  line-height: 16px;
  font-size: 16px;
  letter-spacing: 0.05em;
  display: inline-block;
  vertical-align: middle;
  color: #010101;
}
.menu-item a{
  position: relative;
  padding-bottom: 3px;
}
.menu-item a:hover {
  color: #d5a53c!important;
}

.main-menu {
  position: fixed;
  width: 100%;
  background: #fff;
  top: -150%;
  bottom: 0;
  overflow: scroll;
  max-height: 100vh;
  margin-top: 0;
  left: auto;
  z-index: 990;
  overflow: hidden;
  -webkit-transition: all 1.1s ease-in-out;
  transition: all 1.1s ease-in-out;
  overflow-y: scroll;
  padding-bottom: 50px;
}

.main-menu.open-menu {
  top: 0;
  -webkit-transition: all 1.1s ease-in-out;
  transition: all 1.1s ease-in-out;
}
nav {
  padding-top: 4px;
}
.menu-item a {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #000;
}

.number.white{
  color: #fff;
}
.main-name.white{
  color: #fff;
}
.nav-icon.white .nav-icon__item{
  background: #c4c4c4 !important;
}
.logo-white.active{
  display: inline-block;
}

/*-----HAMBURGER----- */
.nav-icon {
  width: 23px;
  height: 20px;
  display: inline-block;
  overflow: hidden;
  text-align: center;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
.nav-icon .nav-icon__item {
  display: block;
  position: absolute;
  height: 2px;
  background-color: #607025;
  width: 100%;
  opacity: 1;
  margin: 0 auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.nav-icon .nav-icon__item:nth-child(1) {
  top: 0px;
}
.nav-icon .nav-icon__item:nth-child(2) {
  top: 6px;
}
.nav-icon .nav-icon__item:nth-child(3) {
  top: 12px;
}
.nav-icon.open .nav-icon__item:nth-child(1) {
  top: 9px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
  background: #333;
  width: 100%;
}
.nav-icon.open .nav-icon__item:nth-child(2) {
  opacity: 0;
  left: -60px;
}
.nav-icon.open .nav-icon__item:nth-child(3) {
  top: 9px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
  background: #333;
  width: 100%;
}
/*-----END HAMBURGER-----*/

/*-----OPEN MENU-----*/
.menu-list-wrp{
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
}
.menu-list{
  position: relative;
}
.menu-list_flex{
  padding-left: 90px;
}
.menu-list__title{
  position: absolute;
  top: 25%;
  font-size: 21px;
  font-weight: bold;
  display: inline-block;
  opacity: 0.2;
  color: #000;
  transform: rotate(-90deg);
  padding-bottom: 15px;
  border-bottom: 1px dashed;
  text-transform: uppercase;
  text-align: right;
}
.menu-list__title_small{
  width: 84px;
  top: 13%;
}
.menu-list__ul{
  list-style: none;
  padding-left:  140px;
  margin: 0;
  vertical-align: top;
}
.menu-list_no-pad{
  padding-left: 0;
}
.menu-list__ul_full{
  display: inline-block;
  width: auto;
}
.menu-list__ul_small{
  display: inline-block;
  width: auto;
}
.menu-list__item{
  display: inline-block;
  width: auto;
  padding-bottom: 32px;
}
.menu-list_one{
  display: inline-block;
  width: 100%;
}
.menu-list__link{
  font-size: 14px;
  font-weight: bold;
  color: #000;
  text-transform: uppercase;
}
.menu-list__link:hover{
  color: $main-color;
  text-decoration: none;

}
/*-----OPEN MENU END-----*/


.menu-wrap_padd{
  padding-top: 238px;
}

.menu-ul-wrp{
  ul{
    list-style: none;
    padding: 0;
    li{
      padding-bottom: 21px;
    }
    a{
      font-size: 20px;
      transition: 500ms;
      -webkit-transition: 500ms;
      &:hover{
        color: #748A5F;
      }
    }
  }
}
.cart-link {
  padding-right: 20px;
}
.menu-social{
  display: block;
  height: 24px;
  a{
    display: inline-block;
    padding-right: 40px;
    transition: 500ms;
    -webkit-transition: 500ms;
  }
  img{
    transition: 500ms;
    -webkit-transition: 500ms;
  }
}
.menu-foot {
  position: absolute;
  width: 94%;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-top: 1px solid #D5A53C;
}
.menu-lang__item{
  line-height: normal;
  color: #607025;
  font-size: 16px;
  margin-right: 20px;
  cursor: pointer;
  transition: 500ms;
  -webkit-transition: 500ms;
}
.menu-lang__item-active{
  padding: 8px 12px 10px;
  background: #D5A53C;
  border-radius: 50%;
  & span {
    font-weight: bold;
    color: #fff;
  }
}
.hidden-mob{
  display: none !important;
}

// SWITCHER LANG
.swicher__lang ul{display: flex; justify-content: flex-end; align-items: center; color: #607025; width:100%; list-style-type: none;}
.swicher__lang p, .swicher__lang a {color: #607025; font-size: 16px; font-weight: bold;}
.swicher__lang .sub-menu{ display: none; z-index: 99; position: absolute; padding: 5px 10px; background: white; font-weight: normal;}
.swicher__lang .sub-menu a{font-size: 12px; color: #888888;}
.swicher__lang .sub-menu a:hover {color: #607025;}
.swicher-polylang .sub-menu li { margin: 5px 0;}
.swicher-polylang:after { content: '';  display: inline-block; opacity: 0.3; position: absolute; right: 0px; top: 6px; transition: transform 0.5s; width: 7px; height: 7px; border-top: 2px solid; border-right: 2px solid; -moz-transform: rotate(-135deg); -webkit-transform: rotate(-135deg); transform: rotate(135deg); }
.menu-item-has-children { padding: 0 15px; }
.menu-item-has-children:hover{ cursor:pointer; }
.swicher-polylang:hover::after { transform: rotate(315deg);}
.menu-item-has-children:hover .sub-menu{ display: block; position: absolute; right: -7px; height: auto; width: 40px; text-align: center; box-shadow: 0px 2px 3px #000; }

@media (min-width: 992px){
  .header-logo {
    width: 100%;
    margin-left: 0;
  }
  .swicher__lang {
    display: block;
  }
  .mobile-menu, .main-menu {
    display: none !important;
  }
  .hidden-mob{
    display: block !important;
  }
}
@media (min-width: 992px) {
  header .menu-item a {
    margin: 0 10px;
  }
}