// BUTTONS
.bttn {
  display: inline-block;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 100px;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.05em;
  cursor: pointer;
  transition: 500ms;
  -webkit-transition: 500ms;
}
.main__btn{
  background: #D5A53C;
  color: #fff;
  &:hover{
    background: #C08F24;
    color:  #fff;
  }
}
.second__btn {
  border: 1px solid #D5A53C;
  background: #fff;
  color: #D5A53C;
  transition: color 0.2s cubic-bezier(0.47, 0, 0.745, 0.715), background 0.2s cubic-bezier(0.47, 0, 0.745, 0.715);

  &:hover {
    background: #D5A53C;
    color: #fff;
  }
}
.btn__submit {
  margin: 12px auto 0;
  width: 78%;
  display: block;
}
.btn-request {
  width: auto;
  padding: 14px 24px;
}

@media (min-width: 1200px) {
  .bttn {
    padding-left: 0px;
    padding-right: 0px;
  }
  .btn-request {
    padding: 17px 49px;
  }
}
