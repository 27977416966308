.basket-title_padd{
    padding-bottom: 60px;
}
.basket-title__name{
    display: inline-block;
    line-height: 55px;
    font-size: 35px;
    font-weight: 700;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: #333333;
}
// .basket-table-head__item_padd-l{
//     padding-left: 45px;
// }
.basket-title__name_padd{
    padding-right: 14px;
}
.basket-title__number{
    display: inline-block;
    line-height: 22px;
    font-size: 16px;
    color: #8D8F8D;
}
.basket-table-head {
    border-bottom: 0.75px solid #E0E0E0;
}
.basket-table-head__padd{
    padding-bottom: 13px;
    margin-bottom: 50px;
}
.basket-table-head__item{
    line-height: 28px;
    font-size: 18px;
    letter-spacing: 0.15em;
    color: #333333;
}

.basket-table-body__item{
    display: block;
    background-color: #FFFDEE;
    box-shadow: 8px 20px 100px rgba(0, 0, 0, 0.05);
    position: relative;
}
.basket-table-body__item_padd{
    padding: 21px 39px 32px 16px;
    margin-bottom: 70px;
}
.basket-table__name {
    display: inline-block;
    line-height: 30px;
    font-size: 20px;
    font-weight: bold;
    color: #333333;
    border-bottom: 0.75px solid #E0E0E0;
    display: inline-block;
}
.basket-table__name a{
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    color: #333333;
    display: inline-block;
}
.basket-table__price-one{
    opacity: 0.8;
}
.basket-table__name_padd{
    padding-bottom: 7px;
    margin-bottom: 16px;
}
.basket-table__price-for-one ,.basket-table__price-for-one .woocommerce-Price-amount {
    line-height: 20px;
    font-size: 20px;
    font-weight: bold;
    color: #333333;
}
.basket-table__price-for-one_small{
    font-size: 15px;
    font-weight: bold;
}
.basket-table__price-text {
    line-height: 20px;
    font-size: 15px;
    color: #333333;
}
.basket-table__price-text_big{
    display: inline-block;
    line-height: 20px;
    font-size: 20px;
    color: #333333;
}
.basket-table__number-item .quantity-num {
    margin: 0;
    padding: 0;
    outline: 0;
    width: 56px;
    background-color: transparent;
    line-height: 27px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    color: #333333;
}

.basket-table__number-item .quantity-arrow-minus, .basket-table__number-item .quantity-arrow-plus {
    cursor: pointer;
    padding: 0;
    width: auto;
    outline: 0;
    background-color: transparent;
    outline: none;
    // opacity: 0.4;
    // &:hover{
    //     opacity: 1;
    // }
}
.basket-table__number-item .quantity-arrow-minus {
    padding-right: 10px;
}
.basket-table__amound , .basket-table__taste{
    line-height: 27px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    color: #333333;
}
.basket-table__price-item ,.basket-table__price-item .woocommerce-Price-amount {
    display: inline-block;
    line-height: 33px;
    font-size: 35px;
    font-weight: bold;
    text-align: right;
    color: #333333;
}
.basket-table-body__close{
    position: absolute;
    top: 12px;
    right: 17px;
    transition: 500ms;
    -webkit-transition: 500ms;
    &:hover{
        transform: scale(1.3);
    }
}

.basket-table-body__refresh{
    visibility: hidden;
    position: absolute;
    bottom: 12px;
    right: 17px;
    transition: 500ms;
    -webkit-transition: 500ms;
    appearance: #eee;
    border-bottom: 1px solid #feb101;
    text-decoration: underline;

    input{
        background-color: transparent;
        color: #feb101;
    }
}
.basket-table__price-item_s{
    font-size: 18px;
}
.basket-btn{
    display: inline-block;
    line-height: 23px;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #FEB101;
    border-bottom: 2px solid #FEB101;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    cursor: pointer;
}
.basket-btn_padd{
    padding: 2px 5px;
}
.basket-btn:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #FEB101;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

.basket-btn-wrp-item:hover {
    .basket-btn{
        color: #fff;
    }
    .basket-btn:before {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
    }
}
    
.basket-btn-wrp_padd{
    padding-bottom: 42px;
}
.basket-order{
    text-align: center;
}
.basket-order_padd{
    padding-bottom: 139px;
}
.basket-order__title {
    line-height: 40px;
    font-size: 30px;
    text-align: center;
    text-transform: uppercase;
    color: #333333;
}
.basket-order__title_padd{
    padding-bottom: 15px;
}
.basket-order__price , .basket-order__price span{
    line-height: 33px;
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    color: #333333;
}
.woocommerce-Price-currencySymbol{
    display: none;
}
.basket-order__price_s{
    display: inline-block;
    font-size: 24px;
    font-weight: bold;
}
.basket-order__price_padd{
    padding-bottom: 27px;
}
.basket-order__btn{
    width: 277px;
}

.basket-table__price-item .woocommerce-Price-currencySymbol{
    display: none;
}

.basket-table-body__close a {
    color: #feb101;
    font-size: 25px;
}
.quantity-block-2 .quantity {
    display: inline-block;
    text-align: center;
    margin: 0 auto;
}

.quantity-block-2 .quantity input {
    text-align: center;
    width: 40px;
    color: #333;
}

.quantity-block-2 .quantity-arrow-minus img, .quantity-block-2 .quantity-arrow-plus img {
    filter: grayscale(0%);
}
.basket-table__price-for-one span.woocommerce-Price-amount.amount {
    margin-right: -6px;
}
.basket-table__price-item .woocommerce-Price-amount{
    margin-right: -12px;
}

.woocommerce-message {
    display: none;
}

.woocommerce-cart-tab-container {
    width: 350px;
}
.woocommerce-cart-tab-container .widget_shopping_cart .buttons {
    padding: 20px 10px;
}
.woocommerce-cart-tab-container--right {
    -webkit-transform: translateX(350px);
    -moz-transform: translateX(350px);
    -ms-transform: translateX(350px);
    -o-transform: translateX(350px);
    transform: translateX(350px);
}
.woocommerce-cart-tab-container .widget_shopping_cart .buttons {
    background-color: #f8f8f8;
}
.woocommerce-cart-tab-container .widget_shopping_cart .widgettitle {
    display: none;
}
.woocommerce-cart-tab-container .widget_shopping_cart .buttons .button{
    display: inline-block;
    line-height: 23px;
    font-size: 14px;
    font-weight: bold;
    // letter-spacing: .2em;
    text-transform: uppercase;
    color: #f7af44;
    border-bottom: 2px solid #f7af44;
    vertical-align: middle;
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px transparent;
    position: relative;
    transition-property: color;
    transition-duration: .3s;
    background-color: transparent;
    border-radius: 0;
    padding: 5px 0;
    letter-spacing: 0.1em;
}

.woocommerce .widget_shopping_cart .cart_list li, .woocommerce.widget_shopping_cart .cart_list li {
    padding-left: 10px;
    position: relative;
    padding-top: 25px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e3e1e1 !important;
}
.woocommerce ul.cart_list li a, .woocommerce ul.product_list_widget li a {
    display: block;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: .1em;
    text-transform: uppercase;
    color: #333;
}
.woocommerce .widget_shopping_cart .cart_list li a.remove, .woocommerce.widget_shopping_cart .cart_list li a.remove {
    left: -10px;
    top: 25px;
}
.woocommerce .woocommerce-cart-tab-container a.remove {
    color: #ecb046!important;
    transition: 500ms;
    -webkit-transition: 500ms;
}
.woocommerce a.remove:hover {
    background: transparent;
    color: #333!important;
}
dl.variation {
    display: none;
}
.woocommerce .quantity {
    display: inline-block;
    // padding-top: 10px;
}
.woocommerce ul.cart_list li img, .woocommerce ul.product_list_widget li img {
    margin-left: 0;
    width: 74px;
}
.woocommerce-cart-tab-container .woocommerce-Price-currencySymbol {
    display: inline-block;
}
.woocommerce-mini-cart__total span.woocommerce-Price-amount.amount {
    font-size: 40px;
    display: block;
}
.woocommerce-cart-tab-container .widget_shopping_cart ul.product_list_widget {
    margin-top: 10px;

}
// Checkout

.checkout_padd {
    padding-top: 150px;
    padding-bottom: 150px;
}


// Checkout

@media screen and (max-width: 768px) {
    .woocommerce-cart-tab-container {
      display: block !important;
      width: 275px !important;
    }   
  }