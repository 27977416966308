.faq-collapse {
  padding-bottom: 18px;

  &__title {
    display: flex;
    align-items: flex-start;
    padding: 0;
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.2;
    color: #353635;
    text-align: left;
    border: none;
    background-color: transparent;
  }

  &__icon {
    position: relative;
    flex-shrink: 0;
    width: 35px;
    height: 35px;
    margin-right: 25px;
    border-radius: 50%;
    background-color: #D5A53C;
    transition: background-color 0.3s cubic-bezier(0.47, 0, 0.745, 0.715);

    &::before {
      width: 10px;
      height: 2px;
      opacity: 0;
    }

    &::after {
      width: 2px;
      height: 10px;
    }

    &::before,
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      content: "";
      background-color: #FFFFFF;
      transform: translate(-50%, -50%) rotate(90deg);
      transition: transform 0.4s cubic-bezier(0.47, 0, 0.745, 0.715), opacity 0.4s cubic-bezier(0.47, 0, 0.745, 0.715);
    }
  }

  &__title[aria-expanded="false"] &__icon::before {
    opacity: 1;
    transform: translate(-50%, -50%) rotate( -90deg);
  }

  &__title[aria-expanded="false"] &__icon::after {
    transform: translate(-50%, -50%) rotate(-90deg);
    opacity: 1;
  }

  &__container {
    padding-left: 60px;
    font-size: 14px;
    line-height: 1.2;
    color: #888888;
  }

  @media only screen and (min-width: 1200px) {
    padding-bottom: 22px;

    &__title {
      align-items: center;
      cursor: pointer;
    }

    &__title:hover &__icon,
    &__title:focus &__icon,
    &__title:active &__icon {
      background-color: #C08F24;
    }
  }
}