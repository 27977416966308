.contacts-page {
  min-height: 100vh;
  padding-top: 60px;

  .team-block {
    margin-bottom: 35px;
  }

  .map-block {
    margin-bottom: 50px;
  }

  .creator-block {
    margin-bottom: 50px;
  }

  .proposal {
    padding-top: 40px;
  }

  @media only screen and (min-width: 768px) {
    .proposal {
      flex: 0 0 50%;
      align-self: center;
      max-width: 50%;
      padding: 0;
      overflow: unset;
      background-color: transparent;

      &__title {
        display: none;
      }

      &__descr {
        display: none;
      }

      .container {
        max-width: unset;
        margin: 0;
      }

      .form-wrapp {
        max-width: unset;
        width: 100%;
        padding: 0;
        background: transparent;
        box-shadow: none;
      }
    }

    .creator-block {
      margin-bottom: 0;
    }

    .creator-container {
      position: relative;
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-width: 720px;
      padding-top: 100px;
      padding-right: 15px;
      padding-bottom: 25px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
    }
  }

  @media only screen and (min-width: 992px) {
    .proposal {
      flex: 0 0 42%;
      max-width: 42%;
    }

    .creator-container {
      max-width: 960px;
    }
  }

  @media only screen and (min-width: 1200px) {
    .team-block {
      margin-bottom: 15px;
    }

    .map-block {
      margin-bottom: 60px;
    }
    .creator-card {
      position: relative;
    }
    .creator-card::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      width: 1px;
      height: 170px;
      background: #D5A53C;
      transform: translateY(-50%);
    }

    .proposal {
      align-self: flex-end;
      flex: 0 0 50%;
      max-width: 50%;
      padding-right: 10px;
      padding-left: 10px;

      .container {
        padding-right: 0;
      }

      .form-wrapp {
        form {
          padding: 0;
        }

        p {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(3, 75px);
          grid-template-areas: "name textarea" "email textarea" "phone button";
          gap: 0 30px;

          label {
            margin-bottom: 0;

            &:first-of-type {
              grid-area: name;
            }

            &:nth-of-type(2) {
              grid-area: email;
            }

            &:nth-of-type(3) {
              grid-area: phone;
            }

            &:last-of-type {
              grid-area: textarea;
            }
          }

          label + br {
            display: none;
          }

          .btn__submit {
            grid-area: button;
            width: 270px;
            height: 45px;
            margin-top: 8px;
          }
        }

        textarea {
          height: 105px;
        }
      }
    }

    .creator-container {
      max-width: 1200px;
      padding-top: 122px;
      padding-bottom: 80px;
    }
  }
}