.shop-page {
    .products {
        margin-top: 50px;
        padding-top: 0;
    }
    .products__post--item {
        margin-right: 0;
    }
    .reviews-block {
        padding-top: 40px;
    } 
    .present {
        overflow: hidden;
        position: relative;
        padding-top: 35px;
        padding-bottom: 40px;
        &-background {
            display: none;
        }
        &__image {
            margin-top: 18px;
            & img {
                max-width: 300px;
                border-radius: 20px;
            }
        }
        &__item {
            margin-top: 20px;
            &-receive {
                font-weight: bold;
                margin-top: 13px;
            }
            &-btn {
                margin-top: 19px;
            }
        }
    }
    @media (min-width: 1200px) {
        .products {
            margin-top: 60px;
        }
        .reviews-block {
            padding-top: 79px;
            padding-left: 20px;
            &__wrapper {
                max-height: 310px;
            }
        }
        .present {
            padding-bottom: 68px;
            &-background {
                display: block;
                position: absolute;
                top: 73px;
                left: 93px;
                z-index: -1;
            }
            &__block {
                margin-bottom: 17px;
                padding-left: 22px;
            }
            &__image {
                padding-left: 22px;
                & img {
                    max-width: 100%;
                }
            }
            &__item {
                margin-top: 17px;
                padding-left: 23px !important;
                padding-right: 40px !important;
                &-receive {
                    margin-top: 19px;
                    padding-right: 15px;
                }
                &-btn {
                    margin-top: 40px;
                }
            }
        }
        .products__post--item-btn {
            padding: 13px 55px;
        }
    }
}
