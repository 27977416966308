.application-block {
  position: relative;

  &__title {
    margin-bottom: 30px;
  }

  &__slider {
    padding-right: 150px;
    padding-bottom: 60px;
    padding-left: 150px;
    margin-right: -150px;
    margin-left: -150px;
  }

  &__slide {
    flex: 0 0 270px;
    max-width: 270px;
    margin-right: 15px;
  }

  &__background {
    display: none;
  }

  @media only screen and (min-width: 1200px) {
    &__title {
      margin-bottom: 48px;
    }

    &__background {
      position: absolute;
      top: -63px;
      left: -250px;
      z-index: -1;
      display: block;
    }
  }

  @media only screen and (min-width: 1800px) {
    &__background {
      left: 0;
    }
  }
}