.why-we {
  position: relative;
  padding-top: 50px;
  padding-bottom: 20px;
  overflow: hidden;
  background-color: #FFFBF0; 

  &__title {
    max-width: 200px;
    margin-bottom: 5px;
  }

  &__desc {
    margin-bottom: 30px;
    font-size: 14px;
    line-height: 1.25;
    color: #888888;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-right: -15px;
    margin-bottom: 17px;
    margin-left: -15px;
  }

  &__item {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 15px;
    padding-left: 15px;
    margin-bottom: 30px;
  }

  &__title,
  &__desc,
  &__items {
    position: relative;
    z-index: 1;
  }

  &__background {
    position: absolute;
    top: -20px;
    right: -20px;
  }

  &__image {
    max-width: 200px;
  }

  @media only screen and (min-width: 768px) {
    &__desc {
      max-width: 500px;
    }

    &__item {
      flex: 0 0 25%;
      max-width: 25%;
    }

    &__image {
      max-width: unset;
    }
  }

  @media only screen and (min-width: 992px) {
    &__desc {
      max-width: 670px;
    }
  }

  @media only screen and (min-width: 1200px) {
    padding-top: 80px;
    padding-bottom: 50px;

    &__container {
      position: relative;
    }

    &__background {
      top: -70px;
      right: 65px;
    }

    &__title {
      max-width: unset;
      margin-bottom: 18px;
    }

    &__desc {
      margin-bottom: 35px;
    }

    &__items {
      margin-bottom: 70px;
    }
  }
}