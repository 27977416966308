.reviews-block {
  position: relative;

  &__sliders {
    position: relative;
  }

  &__slider {
    padding-top: 20px;
    padding-right: 150px;
    padding-bottom: 65px;
    padding-left: 150px;
    margin-right: -150px;
    margin-left: -150px;
  }

  &__slide {
    margin-right: 30px;
  }

  &__thumb-slider {
    display: none;
  }

  &__background {
    position: absolute;
    top: 23px;
    right: 0;
    z-index: -1;
  }

  @media only screen and (min-width: 768px)  {
    &__slide {
      flex: 0 0 330px;
      max-width: 330px;
    }
  }

  @media only screen and (min-width: 992px)  {
    &__slide {
      flex: 0 0 445px;
      max-width: 445px;
    }
  }

  @media only screen and (min-width: 1200px) {
    &__title {
      padding-left: 65px;
    }

    &__sliders {
      padding-left: 70px;
    }

    &__slider {
      padding-right: 30px;
      padding-left: 30px;
      padding-top: 40px;
      margin-right: -30px;
      margin-left: -30px;
    }

    &__slide {
      flex-basis: unset;
      max-width: unset;
      margin-right: 50px;
    }

    &__thumb-slider {
      top: 50%;
      left: -10px;
      z-index: 3;
      display: block;
      transform: translateY(-55%);
    }

    &__background {
      top: 50%;
      transform: translateY(-43%);
    }
  }
}