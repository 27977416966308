.posts-block {
  &__title {
    margin-bottom: 30px;
  }

  &__slider {
    padding-right: 150px;
    padding-bottom: 65px;
    padding-left: 150px;
    margin-right: -150px;
    margin-left: -150px;
  }

  &__slide {
    flex: 0 0 270px;
    max-width: 270px;
    margin-right: 20px;
  }

  @media only screen and (min-width: 1200px) {
    &__title {
      margin-bottom: 40px;
    }

    &__slider {
      position: relative;
      padding-right: 15px;
      padding-left: 15px;
      margin-right: -15px;
      margin-left: -15px;

      &::after,
      &::before {
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 2;
        width: 15px;
        content: "";
      }

      &::before {
        left: 0;
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 1) 33%,
          rgba(255, 255, 255, 0.7) 66%,
          rgba(255, 255, 255, 0) 100%
		);
      }

      &::after {
        right: 0;
        background: linear-gradient(
          to left,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 1) 33%,
          rgba(255, 255, 255, 0.7) 66%,
          rgba(255, 255, 255, 0) 100%
        );
      }
    }

    &__slide {
      margin-right: 30px;
    }
  }
}