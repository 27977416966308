.product {
    padding-top: 108px;
    position: relative;
    & .clients__tabs a{
        font-size: 14px;
        margin-top: 2px;
        padding-right: 7px;
    }
    &__block {
        position: absolute;
        top: 30px;
    }
    .shop_attributes {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .variable__attribute{
        width: 50%;
        &:first-child {
            width: 100%;
        }
        &-label {
            font-weight: normal !important;
            font-size: 12px;
            line-height: 15px;
            color: #C4C4C4;
            text-indent: 10px;
        }
        &-param-select {
            background-color: #fff;
            line-height: normal;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.4);
            padding: 7px 0 9px 15px!important;
            width: 100%;
            max-width: 165px;
            min-width: 55px;
            position: relative;
            border: 1px solid rgba(143, 109, 32, 0.3);
            box-sizing: border-box;
            border-radius: 100px;
            & select, option{
                font-size: 14px;
                cursor: pointer;
                color: #000;
                line-height: 22px;
                position: relative;
                z-index: 1;
            }
            & select {
                -webkit-appearance: none;
                appearance: none;
                border: 0;
                background: none;
                outline: 0;
                width: 100%;
            }
            &::after {
                position: absolute;
                content: "";
                top: 17px;
                right: 8px;
                width: 14px;
                height: 7.5px;
                background: url('../images/input/select.svg') no-repeat;
                z-index: 0;
            }
        }
    }
    .simple__attribute{
        width: 49%;
        &-item {
            background-color: #fff;
            line-height: normal;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.4);
            padding: 7px 0 9px 15px!important;
            width: 100%;
            max-width: 165px;
            min-width: 55px;
            position: relative;
            border: 1px solid rgba(143, 109, 32, 0.3);
            box-sizing: border-box;
            border-radius: 100px;
        }
        &-label {
            font-weight: normal !important;
            font-size: 12px;
            line-height: 15px;
            color: #C4C4C4;
            text-indent: 10px;
        }
    }
    .get-price {
        margin-bottom: 13px;
    }
    .cart-btn{
        padding: 13px 66px;
    }
    &__description p{
        margin-top: 25px;
        font-size: 14px;
        line-height: 18px;
        color: #888888;
    }
    .main__btn {
        margin-top: 15px;
        padding-left: 58px;
        padding-right: 58px;
    }
}
#corporate_clients label, #private_clients label {
    font-weight: 400!important;
    font-size: 12px;
    line-height: 15px;
    color: #c4c4c4;
    text-indent: 20px;
}
#corporate_clients label input {
    padding-left: 20px;
}
.same-product {
    position: relative;
    padding-top: 8px;
    padding-bottom: 50px;
    &__wrapper {
       display: none;
    }
}
.single-prod {
    padding-top: 50px;
    position: relative;
    &__wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 255px;
        background: #FFFBF0;
    }
}
.variations_form.cart {
    display: flex;
    flex-wrap: wrap;
}
.flex-control-nav.flex-control-thumbs {
    display: none;
}
.woocommerce-Price-amount, .woocommerce-Price-currencySymbol {
    font-weight: bold;
    font-size: 22px;
    line-height: 24px;
    color: #607025;
}
.woocommerce-Price-amount .woocommerce-Price-currencySymbol{
    font-size: 20px;
}
.product-charac-b2c {
    & label {
        margin-top: 11px;
    }
}
.single_variation_wrap {
    margin-top: 15px;
    width: 100%;
}


@media (min-width: 1200px) {
    .product {
        padding: 0 50px;
        & .clients__tabs a{
            font-size: 16px ;
        }
        &__block {
            position: static;
        }
    }
    .single-prod {
        padding-top: 60px;
        &__wrapper {
            width: 53%;
            height: 100%;
        }
    }
    .same-product {
        padding-top: 90px;
        &__wrapper {
            display: block;
            position: absolute;
            top: 41px;
            right: 190px;
            z-index: -1;
        }
        .products__post {
            padding: 41px 0 57px;
        }
    }
    .flex-control-nav.flex-control-thumbs {
        display: block;
    }
}
.single-title{
    line-height: 33px;
    font-size: 25px;
    color: #4F4F4F;
}
.single-text {
    line-height: 22px;
    font-size: 16px;
    color: #8D8F8D;
}
.more-prodcut__img{
    display: inline-block;
    transition: 500ms;
    -webkit-transition: 500ms;
}
.more-prodcut__img:hover{
    transform: scale(1.4);
    z-index: 9;
}
.more-prodcut__img_wrp{
    padding-right: 11px;
}
.more-prodcut__name{
    line-height: 21px;
    font-size: 16px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #646464;
}
.more-prodcut__name_padd{
    padding-top: 35px;
    padding-bottom: 12px;
}
.more-prodcut__number{
    line-height: 33px;
    font-size: 25px;
    color: #FEB101;
}
.single-text__read-more p{
    padding-bottom: 15px;
}
.single-text__link{
    width: 230px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    align-content: center;
    margin: auto;
}

.single-text__link_padd{
    margin-top: 50px;
    margin-left: 0;
    text-transform: none;
}
.custom_radio {
    margin-right: 4px;
    background-color: #fff;
    border-radius: 50%;
}
.charac-b2b__param-item_padd-s{
    padding-right: 15px;
}
.charac-b2b__param-name_ttu{
    font-size: 20px;
    text-transform: uppercase;
    color: #4F4F4F;
}
.charac-b2b__param-name_padd_b{
    padding-bottom: 35px;
}
.charac-b2b__title_padd-gift{
    padding-bottom: 25px;
}

.charac-b2b__param-name-product {
    line-height: 26px;
    font-size: 20px;
    text-transform: uppercase;
    color: #F7AF44;
}
.charac-b2b__param-name-product_padd{
    padding-bottom: 17px;   
}
.charac-b2b__param-list_flex{
    display: inline-block;
    padding-right: 25px;
}
.charac-b2b__param-name_dark{
    color: #4F4F4F;
}

// Card page

.single-product_p{
    padding-top: 120px;
    // min-height: 85vh;
}

.flex-control-thumbs{
    list-style: none;
}
.flex-control-thumbs li{
    width: 20%;
    float: left;
}
.flex-control-thumbs li {    
    padding: 10px;
}
.flex-control-thumbs {
    margin-left: -10px;
    margin-right: -10px;
}
.woocommerce-product-gallery__trigger {
    position: absolute;
    right: 0;
    top: 0;
}

.product-charac-b2c .variations td {
    width: 100%;
    display: block;
}

// QUANTITY BLOCK
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.woocommerce div.product form.cart div.quantity-block .quantity {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    float: left;
    margin: 0 4px 0 0;
}
.quantity-block {
  font-size: 20px;
  border: 1px solid #ddd9cd;
  background: #fff;
  border-radius: 100px;
  height: 35px;
  width: 165px;
  color: #000;
}
.quantity-arrow-minus,
.quantity-arrow-plus {
  cursor: pointer;
  font-size: 20px;
  padding: 0 12px;
  width: 40px;
  box-sizing: border-box;
  outline: none;
  display: inline-block;
}
.quantity-arrow-minus img,
.quantity-arrow-plus img{
    filter: grayscale(100%);
}

.input-text.qty {
  font-size: 16px;
  padding: 5px 0;
  border-radius: 4px;
  color: #8d8f8d;
  outline: none;
  background: transparent;
}
.quantity {
    display: inline-block;
}



.flex-control-nav .slick-arrow{
    position: absolute;
    top: 50%;
    right: 0;
    left: auto !important;
    width: 75px;
    height: 62px;
    background-color: #f7b147;
    outline: none;
    cursor: pointer;
}
.flex-control-nav .slick-next {
    right: 63px;
}
.flex-control-nav .slick-arrow.slick-prev img {
    margin-left: -15px;
  }
  .slick-arrow.slick-next img {
    margin-right: -15px;
    margin-top: -2px;
  }
.flex-control-nav .slick-arrow.slick-prev {
    right: -15px;
}

.scroll-animation {
    width: 100%;
}
.woocommerce .quantity .qty {
    width: 36px;
    display: inline-block;
    text-align: center;
}

.wpgis-wrap img {
    height: 540px;
    object-fit: cover;
    background-color: #fff;
}
.wpgis-wrap .slick-vertical {
    width: 16%;
    padding-bottom: 50px;
}
.wpgis-pgs {
    width: 83%;
}

#slide-nav-pgs img {
    height: 100px;
    margin-bottom: 20px;
}
.wpgis-pgs .btn-prev, .wpgis-pgs .btn-next {
    opacity: 1;
    margin: 0px;
}

.wpgis-pgs .btn-prev, .wpgis-pgs .btn-next {
    position: absolute;
    top: auto;
    bottom: 0;
    z-index: 999;
    width: 65px;
    height: 65px;
    line-height: 37px;
    border-radius: 0px;
    padding: 0;
    font-size: 25px;
    border: none;
    text-align: center;
    background: #feb101;
}
.wpgis-pgs .btn-prev{
    right: 65px;
}

.wpgis-pgs .btn-prev {
    background-image: url('../../build/images/prev-white.svg');
    background-repeat: no-repeat;
    background-position: 50%; 
}
.wpgis-pgs .btn-next {
    background-image: url('../../build/images/next-white.svg');
    background-repeat: no-repeat;
    background-position: 50%; 
}

.slick-vertical .btn-next {
    background-image: url('../../build/images/next.svg');
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: 50%; 
    position: absolute;
    top: auto;
    bottom: 0;
    opacity: 1;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 50px;
    height: 50px;
}

.vbox-title {
    display: none !important;
}

.charac-b2b__param-item_padd_s{
    padding-top: 12px;
}
.charac-b2b__param-name:first-letter{
    text-transform: uppercase;
}

.first-screen__btn_padd_center {
    margin: 0 auto;
    display: block;
}

.images.wpgis-wrap {
    position: relative;
    z-index: 11;
}
.single-prod-decore{
    position: relative;
}

.single-prod-decore_l1 {
    position: absolute;
    z-index: 1;
    top: -15%;
    left: 22%;
}
.single-prod-decore_l2 {
    position: absolute;
    z-index: 1;
    right: -12%;
    bottom: 10%;
}