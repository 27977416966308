.thumb-slider {
  position: absolute;
  display: none;

  &__item {
    height: 200px;
    width: 50px;
  }

  &__slide {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.2px;

    &.swiper-slide-active {
      font-size: 30px;
      color: #D5A53C;
    }
  }

  &__prev,
  &__next {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    cursor: pointer;
  }

  &__prev {
    top: -50px;
  }

  &__next {
    bottom: -50px;
  }

  &__icon {
    width: 12px;
    height: 12px;
    color: #D5A53C;
  }

  @media only screen and (min-width: 1200px) {
    display: block;
  }
}