.post-card {
  position: relative;

  &__image-wrapper {
    margin-bottom: 20px;
    width: 270px;
    height: 200px;
    overflow: hidden;
    border-radius: 20px;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__title {
    display: -webkit-box;
    height: 60px;
    margin-bottom: 5px;
    overflow: hidden;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.2;
    text-transform: uppercase;
    color: #353635;
    -webkit-box-orient: vertical;  
    -webkit-line-clamp: 3;
  }

  &__desc {
    display: -webkit-box;
    height: 35px;
    margin-bottom: 20px;
    overflow: hidden;
    font-size: 14px;
    line-height: 1.2;
    color: #353635; 
    -webkit-box-orient: vertical;  
    -webkit-line-clamp: 2;
  }


  &__link {
    max-width: 150px;
    padding-left: 52px;
    padding-right: 52px;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
    }
  }

  @media only screen and (min-width: 1200px) {
    &__image-wrapper {
      width: 270px;
      height: 200px;
    }

    &__title,
    &__desc {
      padding-right: 10px;
      padding-left: 10px;
    }

    &__link {
      margin-left: 10px;
      
    }
  }
}