$black: #000000;
$white: #FFFFFF;
$brand-red: #E14D43;
$brand-blue: #42A0E0;
$brand-green: #2AB27B;
$brand-yellow: #ECB115;
$brand-dark-blue: #1C2E3A;
$dark-grey: #4A4A4A;
$light-grey: #E5E5E5;
$llight-grey: #f6f6f6;
$radius: 4px;
$main_color: #9b4d66;
