.application-card {
  &__image-wrapper {
    width: 270px;
    height: 270px;
    overflow: hidden;
    border-radius: 20px;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__line {
    width: 50px;
    height: 3px;
    margin: 25px auto;
    margin-bottom: 20px;
    background: #D5A53C;
  }

  &__title {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.2;
    text-align: center;
    color: #353635;
  }
}