@font-face {
  font-family: 'ProximaNova';
  src: url('../../font/ProximaNova-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'ProximaNova';
  src: url('../../font/ProximaNova-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Cera Pro';
  src: url('../../font/CeraPro-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

html {
  height: 100%;
}

body {
	overflow-x: hidden;
}

body.is-overflowed {
	height: 100%;
	overflow: hidden;
}

main {
	min-height: 100%;
	max-width: 100%;
	overflow-x: hidden;
}


html, body, div, span, p, header, footer, section, h1, h2, h3, h4, h5, h6, ul, li, form, input, textarea, button, ol, a  {
    font-family: 'ProximaNova', sans-serif;
    color: $dark-grey;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    padding: 0;
    outline: 0;
    text-decoration: none;
    border: 0;
    color: #353635;
}

body{
  background: #fff;
  position: relative;
}
button:focus ,button:active {
  outline: none;
}
a , a:hover ,a:focus{
  font-family: 'ProximaNova', sans-serif;
  color: #353635;
  text-decoration: none;
  outline: none;
}
.disable-scroll {
    overflow: hidden;
    max-height: 100vh;
    position: fixed;
    width: 100%;
}
.align-center{
  text-align: center;
}
.align-right{
  text-align: right;
}
.align-left{
  text-align: left;
}

// TITLE
.section-title {
  font-size: 25px;
  line-height: 33px;
  font-weight: bold;
  letter-spacing: 0.05em;
  color: #333333;
  & strong {
    color:  #D5A53C;
  }
}
.section-descr {
  font-size: 14px;
  line-height: 17px;
  color: #888888;
}

// BREADCRUMBS
.breadcrumbs {
  position: relative;
  z-index: 9;
}
.breadcrumbs-page, .breadcrumbs a {
  font-size: 12px;
  line-height: 15px;
  color: #D5A53C;
}

// SECTION TABS
.section-tabs a{
  font-size: 16px;
  line-height: 19px;
  margin-top: 18px;
  color: #888888;
  padding-right: 10px;
  &:hover, &.active {
    margin-right: 10px;
    color: #D5A53C;
  }
  &.active {
    font-weight: bold;
  }
  &:first-child {
    border-right: 1px solid #C4C4C4;
    margin-left: 0;
    margin-right: 10px;
  }
}

// BUTTONS
.bttn {
  display: inline-block;
  padding: 13px 0;
  border-radius: 100px;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.05em;
  cursor: pointer;
  transition: 500ms;
  -webkit-transition: 500ms;
}
.main__btn{
  background: #D5A53C;
  color: #fff;
  &:hover{
    background: #C08F24;
    color:  #fff;
  }
}
.second__btn {
  border: 1px solid #D5A53C;
  background: #fff;
  color: #D5A53C;
  &:hover {
    background: #D5A53C;
    color: #fff;
  }
}
.btn__submit {
  margin: 12px auto 0;
  width: 78%;
  display: block;
}
.btn-request {
  width: auto;
  padding: 14px 24px;
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0,0,0,0);
}

// INPUT-FORM
.input-text-form {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(143, 109, 32, 0.2);
  box-sizing: border-box;
  border-radius: 100px;
  min-height: 35px;
  width: 100%;
  &:focus {
    border: 1px solid rgba(143,109,32,0.6);
  }
}

// SWIPER BUTTONS
.swiper-container-products .swiper-button-next.swiper-button-lock, .swiper-container-products .swiper-button-prev.swiper-button-lock {
  display: none;
}
.swiper-container-products .swiper-button-prev::after,
.swiper-container-products .swiper-button-next::after {
  font-size: 16px;
}

.swiper-vrtcl-btn {
  display: none;
  position: absolute;
  left: 4px;
  width: 12px;
  height: 12px;
  transform: rotate(90deg);
}
.swiper-vertical-button-prev {
  top: 10%;
  &::after {
    content: 'prev';
    font-family: swiper-icons;
    font-size: 12px;
    color: #D5A53C;
  }
}
.input-textarea-form {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(143, 109, 32, 0.2);
  box-sizing: border-box;
  border-radius: 20px;
  height: 90px;
  width: 100%;
  padding: 10px 20px;
}

// LABEL
label {
  margin-bottom: 0;
}
.wpcf7-form label {
  width: 100%;
}
// BOOTSTRAP 
.pos-inh {
  position: inherit;
}
// OTHER
.minus, .plus {
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-color: transparent;
  background-position: 50%;
  background-repeat: no-repeat;
  font-size: 0;
  height: 17px;
  width: 40px;
  opacity: 0.5;
}
.minus:hover, .plus:hover {
  opacity: 1;
}
.minus{
  background-image: url('../../build/images/minus.svg');
}
.plus {
  background-image: url('../../build/images/plus.svg');
}
.woocommerce div.product p.price, .woocommerce div.product span.price {
  color: #77a464;
  font-size: 1.25em;
  padding-left: 10px;
}
.overflow-hidden{
  overflow: hidden;
}
.hidden{
  display: none;
}
// CART
.woocommerce-mini-cart__empty-message {
  font-size: 16px;
  padding-top: 20px;
  padding-left: 20px;
}
.woocommerce-cart-tab {
  opacity: 0;
  pointer-events: none;
}
.product-cart {
  cursor: pointer;
  position: relative;
}
.product-cart:hover .cart-count{
  background: #607025;
}
.cart-count {
  font-weight: bold;
  font-size: 11px;
  line-height: 13px;
  background: #D5A53C;
  min-width: 19px;
  padding: 3px 6px;
  border-radius: 100px;
  color: #fff;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.woocommerce-mini-cart__total {
  display: flex;
  justify-content: space-between;
}
a.button.wc-forward:first-child {
  display: none !important;
}
a.button.wc-forward:nth-child(2) {
  width: 100% !important;
}
.checkout-page {
  background: #fffbf0;
  .products__wrapper-right {
    right: 5%;
  }
}
.checkout-form{
  padding: 70px;
  background: #fff;
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}
.woocommerce-cart-tab-container .widget_shopping_cart p.total {
  padding: 0 10px;
}
// POST
.post-item .post-card__link {
  margin-left: 0;
}
// CHECKOUT
.form__title-county, .woocommerce-billing-fields h3, h3#ship-to-different-address, table.shop_table.woocommerce-checkout-review-order-table tbody, table.shop_table.woocommerce-checkout-review-order-table thead, table.shop_table.woocommerce-checkout-review-order-table tr.cart-subtotal, table.shop_table.woocommerce-checkout-review-order-table tr.order-total {
  display: none;
}
table.shop_table.woocommerce-checkout-review-order-table tr.shipping th {
  display: none;
}
.woocommerce #payment #place_order, .woocommerce-page #payment #place_order {
  padding: 13px 70px;
}
#add_payment_method #payment, .woocommerce-cart #payment, .woocommerce-checkout #payment {
  background: transparent;
  border-radius: 0;
}
.charac-b2b__param-name {
  line-height: 26px;
  font-size: 20px;
  font-weight: bold;
  color: #6e6e6e;
}
.current-menu-item a {
  pointer-events: none;
  cursor: default;
  color: #D5A53C !important;
  padding-bottom: 3px;
  position: relative;
}
.current-menu-item a::after, .menu-item a:hover::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 30px;
  height: 2px;
  background-color: #D5A53C;
}
@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
  .no-gutters {
    margin-right: -15px;
    margin-left: -15px;
  }
  .align-xl-left {
    text-align: left;
  }
  .section-title {
    font-size: 40px;
    line-height: 49px;
  }
  .swiper-btn {
    display: block;
  }
  .btn-request {
    padding: 17px 49px;
  }
  .btn__submit {
    width: 60%;
  }
  .swiper-vrtcl-btn {
    display: block;
  }
  .swiper-pagination {
    display: none;
  }
  // NUMBERS SWIPER
  .numbers__swiper {
    display: block;
    max-height: 300px;
  }
}
