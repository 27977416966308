.menu-animation-number {
  -webkit-animation-duration: 0.6s;
  -o-animation-duration: 0.6s;
  animation-duration: 0.6s;
  -webkit-animation-delay: 0.4s;
  -o-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.menu-animation-logo {
    -webkit-animation-duration: 1.2s;
    -o-animation-duration: 1.2s;
    animation-duration: 1.2s;
    -webkit-animation-delay: 1s;
    -o-animation-delay: 1s;
    animation-delay: 1s;
}
.menu-animation-burger{
    -webkit-animation-duration: 1.8s;
    -o-animation-duration: 1.8s;
    animation-duration: 1.8s;
    -webkit-animation-delay: 1.4s;
    -o-animation-delay: 1.4s;
    animation-delay: 1.4s;
}
.menu-animation-fs-left{
    -webkit-animation-duration: 1s;
    -o-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-delay: 0.8s;
    -o-animation-delay: 0.8s;
    animation-delay: 0.8s;
}
.menu-animation-fs-right{
    -webkit-animation-duration: 1.2s;
    -o-animation-duration: 1.2s;
    animation-duration: 1.2s;
    -webkit-animation-delay: 1s;
    -o-animation-delay: 1s;
    animation-delay: 1s;
}
.menu-animation-fs-eko ,.menu-animation-fs-eko_r{
    -webkit-animation-duration: 2s;
    -o-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-delay: 1s;
    -o-animation-delay: 1s;
    animation-delay: 1s;
}
.menu-animation-fs-title ,.menu-animation-fs-title_r{
    -webkit-animation-duration: 2.4s;
    -o-animation-duration: 2.4s;
    animation-duration: 2.4s;
    -webkit-animation-delay: 1s;
    -o-animation-delay: 1s;
    animation-delay: 1s;
}
.menu-animation-fs-text , .menu-animation-fs-text_r{
    -webkit-animation-duration: 2.8s;
    -o-animation-duration: 2.8s;
    animation-duration: 2.8s;
    -webkit-animation-delay: 1s;
    -o-animation-delay: 1s;
    animation-delay: 1s;
}
.menu-animation-fs-button , .menu-animation-fs-button_r{
    -webkit-animation-duration: 3.4s;
    -o-animation-duration: 3.4s;
    animation-duration: 3.4s;
    -webkit-animation-delay: 1.4s;
    -o-animation-delay: 1.4s;
    animation-delay: 1.4s;
}
.scroll-animation{
  -webkit-animation-duration: 2s;
  -o-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-delay: 2s;
  -o-animation-delay: 2s;
  animation-delay: 2s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
}



.menu-animation-fs-left{
    -webkit-animation-duration: 1.3s;
    -o-animation-duration: 1.3s;
    animation-duration: 1.3s;
    -webkit-animation-delay: 1.1s;
    -o-animation-delay: 1.1s;
    animation-delay: 1.1s;
}
.images-item-r-1{
    -webkit-animation-duration: 1.4s;
    -o-animation-duration: 1.4s;
    animation-duration: 1.4s;
    -webkit-animation-delay: 1.2s;
    -o-animation-delay: 1.2s;
    animation-delay: 1.2s;
}
.images-item-r-2{
    -webkit-animation-duration: 1.6s;
    -o-animation-duration: 1.6s;
    animation-duration: 1.6s;
    -webkit-animation-delay: 1.4s;
    -o-animation-delay: 1.4s;
    animation-delay: 1.4s;
}
.images-item-r-3{
    -webkit-animation-duration: 1.8s;
    -o-animation-duration: 1.8s;
    animation-duration: 1.8s;
    -webkit-animation-delay: 1.6s;
    -o-animation-delay: 1.6s;
    animation-delay: 1.6s;
}
.images-item-r-4{
    -webkit-animation-duration: 2s;
    -o-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-delay: 1.8s;
    -o-animation-delay: 1.8s;
    animation-delay: 1.8s;
}

.product-left , .product-right{
    -webkit-animation-duration: 1s;
    -o-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-delay: 0.8s;
    -o-animation-delay: 0.8s;
    animation-delay: 0.8s;
}

.product-left-up ,.product-right-down{
    -webkit-animation-duration: 3s;
    -o-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-delay: 1.8s;
    -o-animation-delay: 1.8s;
    animation-delay: 1.8s;
}

.form-fade{
    -webkit-animation-duration: 1.2s;
    -o-animation-duration: 1.2s;
    animation-duration: 1.2s;
    -webkit-animation-delay: 0.8s;
    -o-animation-delay:  0.8s;
    animation-delay:  0.8s;
}
.bee-animation{
  -webkit-animation-duration: 2.2s;
  -o-animation-duration: 2.2s;
  animation-duration: 2.2s;
  -webkit-animation-delay: 1.2s;
  -o-animation-delay: 1.2s;
  animation-delay: 1.2s;
//   animation-iteration-count: infinite;
//   -webkit-animation-iteration-count: infinite;
//   -o-animation-iteration-count: infinite;
}
.form-decore-bg-right-fade{
    -webkit-animation-duration: 2.4s;
    -o-animation-duration: 2.4s;
    animation-duration: 2.4ss;
    -webkit-animation-delay: 1.4s;
    -o-animation-delay: 1.4s;
    animation-delay: 1.4s;
}




.product-fade{
  -webkit-animation-duration: 2s;
  -o-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-delay: 1s;
  -o-animation-delay:  1s;
  animation-delay:  1s;
}

.blog-item-fade-1{
  -webkit-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: 0.5s;
  -o-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
.blog-item-fade-2{
  -webkit-animation-duration: 1.4s;
  -o-animation-duration: 1.4s;
  animation-duration: 1.4s;
  -webkit-animation-delay: 0.9s;
  -o-animation-delay: 0.9s;
  animation-delay: 0.9s;
}
.blog-item-fade-3{
  -webkit-animation-duration: 1.8s;
  -o-animation-duration: 1.8s;
  animation-duration: 1.8s;
  -webkit-animation-delay: 1.3s;
  -o-animation-delay: 1.3s;
  animation-delay: 1.3s;
}
.blog-item-fade-4{
  -webkit-animation-duration: 2.8s;
  -o-animation-duration: 2.8s;
  animation-duration: 2.8s;
  -webkit-animation-delay: 1.9s;
  -o-animation-delay: 1.9s;
  animation-delay: 1.9s;
}

.bee-404-one{
    -webkit-animation-duration: 3s;
    -o-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-delay: 2s;
    -o-animation-delay: 2s;
    animation-delay: 2s;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
}
.bee-404-two{
    -webkit-animation-duration: 3.5s;
    -o-animation-duration: 3.5s;
    animation-duration: 3.5s;
    -webkit-animation-delay: 2s;
    -o-animation-delay: 2s;
    animation-delay: 2s;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
}
.bee-404-three{
    -webkit-animation-duration: 2.5s;
    -o-animation-duration: 2.5s;
    animation-duration: 2.5s;
    -webkit-animation-delay: 2s;
    -o-animation-delay: 2s;
    animation-delay: 2s;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
}
.bee-404-four{
    -webkit-animation-duration: 3.7s;
    -o-animation-duration: 3.7s;
    animation-duration: 3.7s;
    -webkit-animation-delay: 2s;
    -o-animation-delay: 2s;
    animation-delay: 2s;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
}