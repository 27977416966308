.page-404{
    position: relative;
    height: 100vh;
}
.page-404__title {
    line-height: 81px;
    font-size: 50px;
    font-weight: bold;
    letter-spacing: 0.15em;
    text-transform: capitalize;
    text-align: center;
    color: #333333;
    text-shadow: 4px 4px 25px rgba(0, 0, 0, 0.15);
}
.page-404__title_padd {
    padding-top: 400px;
}
.page-404__bg{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}
.page-404__bg img{
    width: 100%;
}
.page-404__bee{
    position: absolute;
}
.page-404__bee-1{
    left: 5%;
    bottom: 25%;
}
.bee-404-four_one{
    transform: scale(0.6);
}
.page-404__bee-2{
    left: 15%;
    top: 25%;
}
.page-404__bee-3{
    right: 29%;
    top: 38%;
}
.page-404__bee-4{
    right: 3%;
    top: 30%;   
}
.bee-404-four_scale{
    transform: rotate(-15deg) scale(0.6);
    // transform: rotate(-10deg);
}