.advantage {
  &__image-wrapper  {
    width: 90px;
    height: 90px;
    margin-right: auto;
    margin-left: auto;
  } 

  &__image{
    width: 100%;
    height: 100%;
  }

  &__title {
    position: relative;
    padding-top: 35px;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.2;
    text-align: center;
    color: #353635;

    &::before {
      position: absolute;
      top: 15px;
      left: 50%;
      width: 8px;
      height: 8px;
      content: "";
      border-radius: 50%;
      background-color: #D5A53C;
      transform: translateX(-50%);
    }
  }

  @media only screen and (min-width: 1200px) {
    &__image-wrapper {
      width: 130px;
      height: 130px;
    }

    &__title {
      max-width: 205px;
      margin-right: auto;
      margin-left: auto;
      font-size: 20px;
    }
  }
}