.contact_padd{
    padding-top: 105px;
    margin-bottom: 77px;
}
.contact-wrp{
    position: relative;
    z-index: 99;
}

.contact-screen__bg{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    // background: #FFFDEE;  
}
.contact-screen__bg-map{
    z-index: 99;
    // max-height: 100vh;
    height: 86%;
    width: 50%;
    top: 14%;
    padding-bottom: 30px;
}
div#intergeo_mapIzM {
    height: 100% !important;
}

.contact__title{
    line-height: normal;
    font-size: 60px;
    font-weight: bold;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #4F4F4F;
}
.contact__title_padd{
    padding-bottom: 24px;
}
.contact__name{
    line-height: normal;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.05em;
    color: #333333;
}
.contact__name_padd{
    padding-bottom: 12px;
}

.contact__text , .contact__text a , .contact__text span , .contact__text p{
    line-height: 23px;
    font-size: 14px;
    letter-spacing: 0.02em;
    color: #919191;
}
.contact__text span{
    padding-left: 15px;
}
.contact__text_padd{
    padding-bottom: 40px;
}
.social-contact{
    padding-left: 0;
    padding-bottom: 50px;

}
.social-contact a {
    display: inline-block;
    margin-right: 25px;
}
.contact-white{
    color: #fff;
}
.nav-icon.contact-white .nav-icon__item{
    background: #fff;
}