.review {
  padding-top: 25px;
  padding-right: 25px;
  padding-bottom: 30px;
  padding-left: 25px;
  background: rgba(255, 251, 240, 0.8);
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 20px;

  &__image-wrapper {
    width: 140px;
    height: 140px;
    margin-bottom: 20px;
    border-radius: 50%;
    overflow: hidden;
  }

  &__image {
    width: 100%;
    height: 100% !important;
    object-fit: cover;
    object-position: center;
  }

  &__name {
    position: relative;
    margin-bottom: 25px;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: #000000;

    &::after {
      position: absolute;
      bottom: -11px;
      left: 0;
      width: 100px;
      height: 2px;
      background-color: #D5A53C;
      content: "";
    }
  }

  &__desc {
    margin-bottom: 30px;
    font-size: 14px;
    line-height: 1.25;
    color: #353635;
  }

  @media only screen and (min-width: 1200px) {
    display: flex;
    align-items: center;
    padding-top: 60px;
    padding-right: 90px;
    padding-bottom: 60px;
    padding-left: 70px;
    max-width: 770px;

    &__image-wrapper {
      flex-shrink: 0;
      flex-basis: 160px;
      width: 160px;
      height: 160px;
      margin-right: 40px;
      margin-bottom: 0;
    }

    &__name {
      margin-bottom: 40px;

      &::after {
        bottom: -13px;
        width: 140px;
      }
    }

    &__desc {
      margin-bottom: 15px;
    }
  }
}