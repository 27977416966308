.team-card  {
  max-width: 165px;

  &__image-wrapper {
    width: 150px;
    height: 150px;
    margin-bottom: 10px;
    overflow: hidden;
    border-radius: 50%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }

  &__stub {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #F8F8F8;
  }

  &__stub-image {
    max-width: 70px;
    max-height: 70px;
    margin-left: 12px;
  }

  &__name {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: 0.05em;
    color: #353635;
  }

  &__position {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 1.2;
    color: #353635;
  }

  &__phone {
    display: block;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.2;
    color: #607025;

    &:hover,
    &:focus,
    &:active {
      color: #607025;
      text-decoration: none;
    }
  }

  @media only screen and (max-width: 767.98px) {
    &--centered {
      max-width: unset;
      text-align: center;
    }

    &--centered &__image-wrapper {
      margin-right: auto;
      margin-bottom: 20px;
      margin-left: auto;
    }

    &--centered &__social {
      justify-content: center;
    }
  }

  @media only screen and (min-width: 768px) {
    display: flex;
    align-items: center;
    max-width: unset;

    &__image-wrapper {
      flex: 0 0 150px;
      max-width: 150px;
      margin-right: 15px;
    }
  }

  @media only screen and (min-width: 1200px) {
    &__image-wrapper {
      flex: 0 0 160px;
      max-width: 160px;
      width: 160px;
      height: 160px;
      margin-right: 30px;
    }

    &__desc {
      padding-bottom: 12px;
    }

    &__position {
      margin-bottom: 30px;
    }
  }
}