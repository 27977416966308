//  SWIPER

// SWIPER BUTTONS
.swiper-container-products .swiper-button-prev,
.swiper-container-products .swiper-button-next {
  top: 50%;
  width: 40px;
  height: 40px;
  color: white;
  background: #D5A53C;
  border-radius: 20px;
}
.swiper-container-products .swiper-button-prev {
  left: 0px;
  transform: translateX(-50%);
}
.swiper-container-products .swiper-button-next {
  right: -30px;
  transform: translateX(-30%);
}
.swiper-container-products .swiper-button-prev::after,
.swiper-container-products .swiper-button-next::after {
  font-size: 16px;
  position: absolute;
  left: 40%;
  top: 30%;
}
div.swiper-btn {
  display: none;
}
.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #000;
  opacity: 1;
  background: rgba(0, 0, 0, 0.2);
}
:root {
  --swiper-theme-color: #d5a53c;
}
// VERTICAL SWIPER BUTTON
.swiper-slide-visible {
  font-weight: bold;
  font-size: 14px;
  width: 32px;  
  color: #C4C4C4;
  background: transparent;
  cursor: pointer;
  max-height: 30px;
}
.swiper-vrtcl-btn {
  display: none;
  position: absolute;
  left: 4px;
  width: 12px;
  height: 12px;
  transform: rotate(90deg);
}
.swiper-vertical-button-prev {
  top: 10%;
  &::after {
    content: 'prev';
    font-family: swiper-icons;
    font-size: 12px;
    color: #D5A53C;
  }
}
.swiper-vertical-button-next {
  bottom: 10%;
  &::after {
    content: 'next';
    font-family: swiper-icons;
    font-size: 12px;
    color: #D5A53C;
  }
}
.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  transform: none;
}
.swiper-pagination-bullets .swiper-pagination-bullet-active,
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active {
  background-color: #D5A53C;
}
// NUMBERS SWIPER
.numbers__swiper {
  overflow: hidden;
  display: none;
  margin-top: 37px;
}
// THUMB GALLERY
.thumb-swiper-container {
    display: none;
    max-height: 550px;
    float: left;
}
.thumb-swiper-container .gallery__image {
    max-width: 90px;
    max-height: 90px;
}
.thumb-swiper-container img {
    object-fit: contain;
    height: 90px !important;
}
// PRODUCT GALLERY
.swiper-container-gallery {
    max-height: 240px;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.05);
    background: #fff;
}
.swiper-container-gallery img {
    object-fit: contain;
    max-height: 240px;
}
@media (min-width: 1200px) {
    // THUMB GALLERY
    .thumb-swiper-container {
        display: block;
    }
    // PRODUCT GALLERY
    .swiper-container-gallery {
        margin-left: 110px;
    }
    .swiper-container-gallery, .swiper-container-gallery img {
        max-height: 570px;
        max-width: 570px;
    }
    div.swiper-btn {
      display: block;
    }
}