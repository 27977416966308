.swiper-pagination {
  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    margin: 0 6px !important;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    color: #000;
    opacity: 1;
    background-color: #C4C4C4;
  }

  .swiper-pagination-bullet-active {
    background-color: #D5A53C;
  }
}