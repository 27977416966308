.creator-block {
  &__title {
    margin-bottom: 10px;
  }

  &__slogan {
    margin-bottom: 20px;
  }

  @media only screen and (min-width: 768px) {
    flex: 0 0 50%;
    max-width: 50%;

    &__container {
      max-width: unset;
      margin: 0;
    }

    &__desc {
      position: absolute;
      top: 0;
    }
  }

  @media only screen and (min-width: 992px) {
    flex: 0 0 58%;
    max-width: 58%;
  }

  @media only screen and (min-width: 1200px) {
    flex: 0 0 50%;
    max-width: 50%;

    &__container {
      padding-left: 5px;
    }

    &__desc {
      top: 15px;
      left: 15px;
    }

    &__title {
      margin-bottom: 0;
    }
  }
}