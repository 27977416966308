.creator-card {
  &__box {
    max-width: 280px;
    margin: 0 auto;
    margin-bottom: 20px;
    border-radius: 50%;
    overflow: hidden;
  }

  &--about &__box {
    max-width: 190px;
  }

  &__box-item {
    position: relative;
    height: 1px;
    padding-bottom: 100%;
  }

  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__name {
    margin-bottom: 5px;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2;
    text-align: center;
    letter-spacing: 0.05em;
    color: #353635;
  }

  &__position {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 1.2;
    text-align: center;
    letter-spacing: 0.1em;
    color: #353635;
  }

  &__text {
    position: relative;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 1.25;
    text-align: center;
    color: #888888;

    &::after,
    &::before {
      position: absolute;
      width: 20px;
      height: 20px;
      content: "";
      background-image: url("data:image/svg+xml,%3Csvg width='15' height='12' viewBox='0 0 15 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 7.9646C0 10.5664 1.56475 12 3.34532 12C4.96403 12 6.20504 10.7257 6.20504 9.18584C6.20504 7.59292 5.1259 6.47788 3.61511 6.47788C3.34532 6.47788 2.96763 6.53097 2.91367 6.53097C3.07554 4.83186 4.64029 2.70796 6.36691 1.59292L4.3705 0C1.88849 1.75221 0 4.72566 0 7.9646ZM8.63309 7.9646C8.63309 10.5664 10.1978 12 11.9784 12C13.5971 12 14.8921 10.7257 14.8921 9.18584C14.8921 7.59292 13.759 6.47788 12.2482 6.47788C11.9784 6.47788 11.6007 6.53097 11.5468 6.53097C11.7626 4.83186 13.2734 2.70796 15 1.59292L13.0036 0C10.5216 1.75221 8.63309 4.72566 8.63309 7.9646Z' fill='%23D5A53C'/%3E%3C/svg%3E%0A");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 15px 12px;
    }

    &::before {
      top: -25px;
      left: -3px;
    }

    &::after {
      top: unset;
      right: -3px;
      bottom: -25px;
      left: unset;
      transform: rotate(180deg);
    }
  }

  &__email {
    display: block;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 14px;
    line-height: 1.2;
    text-align: center;
    color: #607025;

    &:hover,
    &:focus,
    &:active {
      color: #607025;
      text-decoration: none;
    }
  }

  &__socials {
    justify-content: center;
  }

  @media only screen and (min-width: 992px) {
    display: flex;
    align-items: center;

    &__box {
      flex: 0 0 265px;
      max-width: 265px;
      margin-right: 30px;
      margin-bottom: 0;
    }

    &--about &__box {
      flex: 0 0 250px;
      max-width: 250px;
    }

    &__name {
      font-size: 30px;
      text-align: left;
    }

    &__position {
      text-align: left;
    }

    &__text {
      text-align: left;

      &::before {
        top: -20px;
        left: -23px;
      }

      &::after {
        top: unset;
        right: -23px;
        bottom: -20px;
        left: unset;
      }
    }

    &__email {
      margin-bottom: 30px;
      text-align: left;
    }

    &__socials {
      justify-content: flex-start;
    }
  }

  @media only screen and (min-width: 1200px) {
    &--about &__box {
      flex: 0 0 300px;
      max-width: 300px;
      margin: 0;
      margin-right: 130px;
    }

    &--about &__desc {
      max-width: 620px;
      margin-bottom: 15px;
    }

    &__text {
      margin-bottom: 15px;

      &::before {
        top: -42px;
        left: -62px;
      }

      &::after {
        top: unset;
        right: -39px;
        bottom: -36px;
        left: unset;
      }

      &::before,
      &::after {
        width: 45px;
        height: 45px;
        background-size: 42px 33px;
      }
    }
  }
}