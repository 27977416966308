.products {
    padding-top: 48px;
    padding-bottom: 130px;
    position: relative;
    background: #FFFBF0;
    overflow: hidden;
    &.section {
      & .products__post {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
    &__wrapper {
      position: absolute;
      &-left {
        top: 76px;
        right: -251px;
        &-img {
          width: 160px;
        }
      }
      &-right {
        display: none;
      }
    }
    &__post{
      padding-top: 57px;
      padding-bottom: 57px;

      &--item{
        min-height: 350px;
        margin-right: 15px;
        margin-bottom: 30px;
        padding: 30px 27px 25px !important;
        background: #fff;
        box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
        position: relative;
        &-img {
          display: block;
          width: 210px;
          height: 245px !important;
          object-fit: contain;
          margin: auto;
        }
        &-title {
          display: flex;
          justify-content: space-between;
          font-weight: bold;
          font-size: 20px;
          line-height: 29px;
          color: #000;
          padding-top: 19px;
          border-top: 1px solid #eed8aa;
          height: 80px;
          
          & .prod-name {
            font-weight: bold;
            font-size: 20px;
            line-height: 29px;
            
          }
          & strong {
            color: #607025;
            white-space: nowrap;
            word-spacing: normal;
          }
        }
        &-btn {
          display: block;
          margin-top: 20px;
          width: 100%;
        }
      }
    }
    &__swiper {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
@media (min-width:1200px) {
    .products {
        padding-top: 108px;
        padding-bottom: 43px;
        &__wrapper {
          &-left {
            top: 33px;
            right: inherit;
            left: 24%;
            transform: translateX(-50%);
            &-img {
              width: 320px;
            }
          }
          &-right {
            top: 164px;
            right: 25%;
            transform: translateX(-50%);
            display: block;
          }
        }
        &__block {
          padding-left: 12px !important;
        }
        &__post {
          padding: 57px 0;
          &--item {
            &:hover .products__post--item-cart {
              display: block;
              position: absolute;
              top: calc(100% - 30px);
              left: 0;
              background: #fff;
              width: 100%;
              padding: 0 28px 20px;
              border-radius: 0 0 20px 20px;
              box-shadow: 10px 27px 30px rgba(0,0,0,.1);
              z-index: 9999999;
            }
            &-cart {
              display: none;
            }
            &-btn {
              width: 100%;
              padding: 13px 67px;
            }
          }
        }
        &__swiper {
          margin-left: -15px;
          margin-right: -15px;
          overflow: inherit !important;
        }
        &__swiper-wrapper {
          align-items: flex-start;
          .woocommerce-Price-amount, .woocommerce-Price-currencySymbol {
            white-space: nowrap;
          }

          .swiper-slide {
            opacity: 0;
            transition: opacity 0.3s cubic-bezier(0.47, 0, 0.745, 0.715);
            pointer-events: none;
          }
          .swiper-slide-active {
            opacity: 1;
            & + .swiper-slide, & + .swiper-slide + .swiper-slide, & + .swiper-slide + .swiper-slide + .swiper-slide {
              opacity: 1;
              pointer-events: auto;
            }
          }
        }
    }
}
