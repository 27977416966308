.about-hero {
  position: relative;
  padding-bottom: 327px;

  &__title {
    margin-bottom: 5px;
    font-size: 25px;
    font-weight: 700;
    line-height: 1.32;
    letter-spacing: 0.05em;
    text-transform: uppercase; 
    
    strong {
      color: #D5A53C;
    }
  }

  &__desc {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 1.25;
    color: #888888;
  }

  &__button {
    max-width: 240px;
    height: 45px;
    padding: 0 10px;
    font-weight: 700;
    line-height: 45px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }

  &__background {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
  }

  &__image {
    max-width: 100%;
  }

  @media only screen and (min-width: 768px) {
    height: 320px;
    padding-bottom: unset;

    &__container {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__title {
      max-width: 55%;
    }

    &__desc {
      max-width: 55%;
    }
  }

  @media only screen and (min-width: 1200px) {
    height: unset;
    padding-bottom: 230px;

    &__title {
      max-width: 45%;
      margin-bottom: 18px;
      font-size: 50px;
      line-height: 1.25;
    }

    &__desc {
      max-width: 35%;
      margin-bottom: 25px;
      font-size: 16px;
    }

    &__button {
      max-width: 270px;
      height: 50px;
      font-size: 14px;
      line-height: 50px;
    }

    &__background {
      bottom: 50px;
    }
  }

  @media only screen and (min-width: 1500px) {
    &__background {
      bottom: 15px;
    }
  }
}