.title {
  font-size: 25px;
  font-weight: 700;
  line-height: 1.3;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #000000;

  @media only screen and (min-width: 768px) {
    font-size: 30px;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 40px; 
  }
}

.slogan {
  font-size: 14px;
  line-height: 1.2;
  color: #888888;
}