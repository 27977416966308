.map-popup {
  height: 130px;
  margin-bottom: 0;

  .leaflet-popup-content-wrapper {
    min-width: 210px;
    min-height: 130px;
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
  }

  .leaflet-popup-tip-container {
    display: none;
  }

  .leaflet-popup-content {
    padding: 20px 15px;
    padding-left: 18px;
    margin: 0;

    h2, h3, h4 {
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: 700;
      line-height: 1.2;
      color: #353635;
    }
  
    p {
      margin: 0;
      margin-bottom: 12px;
      font-size: 13px;
      line-height: 1.2;
      color: #353635;
    }
  
    b {
      display: block;
      font-weight: 700;
    }
  } 

  &__phone,
  &__email {
    display: block;
    font-size: 13px;
    line-height: 1.2;
    color: #353635 !important;

    &:hover,
    &:focus,
    &:active {
      color: #353635 !important;
    }
  }

  &__phone {
    margin-bottom: 3px;
    font-weight: 700;
  }

  &__email {
    margin-bottom: 10px;
    text-decoration: underline;

    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
    }
  }
}